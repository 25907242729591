import React, { useEffect, useMemo, useState } from 'react';
import { SelectableValue } from '@grafana/data';
import { Button, Modal, FieldSet, Field, Select } from '@grafana/ui';
import { IntervalPicker } from 'components/IntervalPicker';
import { useGrafanaAlertGroup } from 'hooks/useGrafanaAlertGroup';
import { useTranslation } from 'react-i18next';
import { AlertGroup } from 'shared/alertingTypes';

export interface AlertConfigModalProps {
  namespace: string;
  lookback?: string;
  groupname?: string;
  isOpen: boolean;
  onSelect: (group: AlertGroup, level: string | undefined, lookback: string) => void;
  onCancel: () => void;
  onDismiss: () => void;
}

export const AlertConfigModal = (props: AlertConfigModalProps) => {
  const { t } = useTranslation();
  const [namespace] = useState<string>(props.namespace);
  const [groupnames] = useGrafanaAlertGroup(namespace);

  const groupOptions = useMemo<Array<SelectableValue<AlertGroup>>>(() => {
    const defaultGroups: Array<AlertGroup> = [
      { name: t('converter.alerting.minute'), interval: '1m', rules: [] },
      { name: t('converter.alerting.fiveMinute'), interval: '5m', rules: [] },
      { name: t('converter.alerting.hour'), interval: '1h', rules: [] },
      { name: t('converter.alerting.sixHours'), interval: '6h', rules: [] },
      { name: t('converter.alerting.day'), interval: '1d', rules: [] },
    ];
    const finalGroups = [...(groupnames ?? [])];
    defaultGroups.forEach((emptyGroup) => {
      if (
        groupnames === undefined ||
        groupnames?.map((group) => group.interval !== emptyGroup.interval).reduce((a, b) => a && b, true)
      ) {
        finalGroups.push(emptyGroup);
      }
    });
    return finalGroups.map((group) => ({
      label: group.name + (group.interval ? ' [' + group.interval + ']' : ''),
      value: group,
    }));
  }, [t, groupnames]);
  const levelOptions: Array<SelectableValue<string>> = [
    {
      value: 'informational',
      label: t('converter.alerting.informational'),
      description: t('converter.alerting.informationalDetail'),
    },
    {
      value: 'low',
      label: t('converter.alerting.low'),
      description: t('converter.alerting.lowDetail'),
    },
    {
      value: 'medium',
      label: t('converter.alerting.medium'),
      description: t('converter.alerting.mediumDetail'),
    },
    {
      value: 'high',
      label: t('converter.alerting.high'),
      description: t('converter.alerting.highDetail'),
    },
    {
      value: 'critical',
      label: t('converter.alerting.critical'),
      description: t('converter.alerting.criticalDetail'),
    },
  ];

  const [group, setGroup] = useState<SelectableValue<AlertGroup> | undefined>();
  const [groupInvalid, setGroupInvalid] = useState<boolean>(false);
  const [level, setLevel] = useState<SelectableValue<string>>();
  const [lookback, setLookback] = useState<string>(props.lookback ?? '');

  useEffect(() => {
    setGroup(
      groupOptions.find((grp) => {
        return grp.value?.name === props.groupname;
      })
    );
  }, [props.groupname, groupOptions]);

  return (
    <Modal
      title={t('converter.alerting.alertConf')}
      isOpen={props.isOpen}
      onDismiss={props.onDismiss}
      onClickBackdrop={props.onDismiss}
    >
      <>
        <FieldSet>
          <Field
            label={t('converter.alerting.frequency')}
            description={t('converter.alerting.frequencyDetail')}
            required={true}
          >
            <Select
              onChange={(value) => {
                setGroup(value);
              }}
              options={groupOptions}
              value={group}
              invalid={groupInvalid}
              data-testid="group-select"
            />
          </Field>
          <Field label={t('converter.alerting.level')} description={t('converter.alerting.levelDetail')}>
            <Select
              onChange={(value) => {
                setLevel(value);
              }}
              isClearable={true}
              options={levelOptions}
              value={level}
            />
          </Field>
          <Field label={t('converter.alerting.lookback')} description={t('converter.alerting.lookbackDetail')}>
            <IntervalPicker
              onIntervalChanged={(interval) => {
                setLookback(interval);
              }}
            />
          </Field>
        </FieldSet>
        <Modal.ButtonRow>
          <Button variant="secondary" fill="outline" onClick={props.onCancel}>
            {t('buttons.cancel')}
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              if (group && group.value) {
                props.onSelect(group.value, level?.value, lookback);
              } else {
                setGroupInvalid(true);
              }
            }}
          >
            {t('buttons.confirm')}
          </Button>
        </Modal.ButtonRow>
      </>
    </Modal>
  );
};
