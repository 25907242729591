// tslint:disable
/**
 * Detect service API.
 * The Detect service exposes an HTTP API to do actions within the plugin like saving pipelines, conversions of rules, and manage external rule sources.
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: security-operations@grafana.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Observable } from 'rxjs';
import type { AjaxResponse } from 'rxjs/ajax';
import { BaseAPI, throwIfNullOrUndefined, encodeURI } from '../runtime';
import type { OperationOpts, HttpHeaders, HttpQuery } from '../runtime';
import type {
  CreatePipelineRequestModel,
  ListConversion400ResponseModel,
  ListPipeline200ResponseModel,
  ListPipelineConversions200ResponseModel,
  ListPipelineDataSourceConfigs200ResponseModel,
  PipelineCreateModel,
  PipelineReadModel,
  PipelineUpdateModel,
  UpdatePipelineRequestModel,
} from '../models';

export interface CreatePipelineRequest {
  createPipelineRequestModel: CreatePipelineRequestModel;
}

export interface DeletePipelineRequest {
  id: string;
}

export interface ListPipelineRequest {
  page?: number;
  itemsPerPage?: number;
  startTime?: string;
  endTime?: string;
}

export interface ListPipelineConversionsRequest {
  id: string;
  page?: number;
  itemsPerPage?: number;
  startTime?: string;
  endTime?: string;
}

export interface ListPipelineDataSourceConfigsRequest {
  id: string;
  page?: number;
  itemsPerPage?: number;
  startTime?: string;
  endTime?: string;
}

export interface ReadPipelineRequest {
  id: string;
}

export interface UpdatePipelineRequest {
  id: string;
  updatePipelineRequestModel: UpdatePipelineRequestModel;
}

/**
 * no description
 */
export class PipelineApi extends BaseAPI {
  /**
   * Creates a new Pipeline and persists it to storage.
   * Create a new Pipeline
   */
  createPipeline({ createPipelineRequestModel }: CreatePipelineRequest): Observable<PipelineCreateModel>;
  createPipeline(
    { createPipelineRequestModel }: CreatePipelineRequest,
    opts?: OperationOpts
  ): Observable<AjaxResponse<PipelineCreateModel>>;
  createPipeline(
    { createPipelineRequestModel }: CreatePipelineRequest,
    opts?: OperationOpts
  ): Observable<PipelineCreateModel | AjaxResponse<PipelineCreateModel>> {
    throwIfNullOrUndefined(createPipelineRequestModel, 'createPipelineRequestModel', 'createPipeline');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
    };

    return this.request<PipelineCreateModel>(
      {
        url: '/pipelines',
        method: 'POST',
        headers,
        body: createPipelineRequestModel,
      },
      opts?.responseOpts
    );
  }

  /**
   * Deletes the Pipeline with the requested ID.
   * Deletes a Pipeline by ID
   */
  deletePipeline({ id }: DeletePipelineRequest): Observable<void>;
  deletePipeline({ id }: DeletePipelineRequest, opts?: OperationOpts): Observable<void | AjaxResponse<void>>;
  deletePipeline({ id }: DeletePipelineRequest, opts?: OperationOpts): Observable<void | AjaxResponse<void>> {
    throwIfNullOrUndefined(id, 'id', 'deletePipeline');

    return this.request<void>(
      {
        url: '/pipelines/{id}'.replace('{id}', encodeURI(id)),
        method: 'DELETE',
      },
      opts?.responseOpts
    );
  }

  /**
   * List Pipelines.
   * List Pipelines
   */
  listPipeline({
    page,
    itemsPerPage,
    startTime,
    endTime,
  }: ListPipelineRequest): Observable<ListPipeline200ResponseModel>;
  listPipeline(
    { page, itemsPerPage, startTime, endTime }: ListPipelineRequest,
    opts?: OperationOpts
  ): Observable<AjaxResponse<ListPipeline200ResponseModel>>;
  listPipeline(
    { page, itemsPerPage, startTime, endTime }: ListPipelineRequest,
    opts?: OperationOpts
  ): Observable<ListPipeline200ResponseModel | AjaxResponse<ListPipeline200ResponseModel>> {
    const query: HttpQuery = {};

    if (page != null) {
      query['page'] = page;
    }
    if (itemsPerPage != null) {
      query['itemsPerPage'] = itemsPerPage;
    }
    if (startTime != null) {
      query['startTime'] = (startTime as any).toISOString();
    }
    if (endTime != null) {
      query['endTime'] = (endTime as any).toISOString();
    }

    return this.request<ListPipeline200ResponseModel>(
      {
        url: '/pipelines',
        method: 'GET',
        query,
      },
      opts?.responseOpts
    );
  }

  /**
   * List attached Conversions.
   * List attached Conversions
   */
  listPipelineConversions({
    id,
    page,
    itemsPerPage,
    startTime,
    endTime,
  }: ListPipelineConversionsRequest): Observable<ListPipelineConversions200ResponseModel>;
  listPipelineConversions(
    { id, page, itemsPerPage, startTime, endTime }: ListPipelineConversionsRequest,
    opts?: OperationOpts
  ): Observable<AjaxResponse<ListPipelineConversions200ResponseModel>>;
  listPipelineConversions(
    { id, page, itemsPerPage, startTime, endTime }: ListPipelineConversionsRequest,
    opts?: OperationOpts
  ): Observable<ListPipelineConversions200ResponseModel | AjaxResponse<ListPipelineConversions200ResponseModel>> {
    throwIfNullOrUndefined(id, 'id', 'listPipelineConversions');

    const query: HttpQuery = {};

    if (page != null) {
      query['page'] = page;
    }
    if (itemsPerPage != null) {
      query['itemsPerPage'] = itemsPerPage;
    }
    if (startTime != null) {
      query['startTime'] = (startTime as any).toISOString();
    }
    if (endTime != null) {
      query['endTime'] = (endTime as any).toISOString();
    }

    return this.request<ListPipelineConversions200ResponseModel>(
      {
        url: '/pipelines/{id}/conversions'.replace('{id}', encodeURI(id)),
        method: 'GET',
        query,
      },
      opts?.responseOpts
    );
  }

  /**
   * List attached DataSourceConfigs.
   * List attached DataSourceConfigs
   */
  listPipelineDataSourceConfigs({
    id,
    page,
    itemsPerPage,
    startTime,
    endTime,
  }: ListPipelineDataSourceConfigsRequest): Observable<ListPipelineDataSourceConfigs200ResponseModel>;
  listPipelineDataSourceConfigs(
    { id, page, itemsPerPage, startTime, endTime }: ListPipelineDataSourceConfigsRequest,
    opts?: OperationOpts
  ): Observable<AjaxResponse<ListPipelineDataSourceConfigs200ResponseModel>>;
  listPipelineDataSourceConfigs(
    { id, page, itemsPerPage, startTime, endTime }: ListPipelineDataSourceConfigsRequest,
    opts?: OperationOpts
  ): Observable<
    ListPipelineDataSourceConfigs200ResponseModel | AjaxResponse<ListPipelineDataSourceConfigs200ResponseModel>
  > {
    throwIfNullOrUndefined(id, 'id', 'listPipelineDataSourceConfigs');

    const query: HttpQuery = {};

    if (page != null) {
      query['page'] = page;
    }
    if (itemsPerPage != null) {
      query['itemsPerPage'] = itemsPerPage;
    }
    if (startTime != null) {
      query['startTime'] = (startTime as any).toISOString();
    }
    if (endTime != null) {
      query['endTime'] = (endTime as any).toISOString();
    }

    return this.request<ListPipelineDataSourceConfigs200ResponseModel>(
      {
        url: '/pipelines/{id}/data-source-configs'.replace('{id}', encodeURI(id)),
        method: 'GET',
        query,
      },
      opts?.responseOpts
    );
  }

  /**
   * Finds the Pipeline with the requested ID and returns it.
   * Find a Pipeline by ID
   */
  readPipeline({ id }: ReadPipelineRequest): Observable<PipelineReadModel>;
  readPipeline({ id }: ReadPipelineRequest, opts?: OperationOpts): Observable<AjaxResponse<PipelineReadModel>>;
  readPipeline(
    { id }: ReadPipelineRequest,
    opts?: OperationOpts
  ): Observable<PipelineReadModel | AjaxResponse<PipelineReadModel>> {
    throwIfNullOrUndefined(id, 'id', 'readPipeline');

    return this.request<PipelineReadModel>(
      {
        url: '/pipelines/{id}'.replace('{id}', encodeURI(id)),
        method: 'GET',
      },
      opts?.responseOpts
    );
  }

  /**
   * Updates a Pipeline and persists changes to storage.
   * Updates a Pipeline
   */
  updatePipeline({ id, updatePipelineRequestModel }: UpdatePipelineRequest): Observable<PipelineUpdateModel>;
  updatePipeline(
    { id, updatePipelineRequestModel }: UpdatePipelineRequest,
    opts?: OperationOpts
  ): Observable<AjaxResponse<PipelineUpdateModel>>;
  updatePipeline(
    { id, updatePipelineRequestModel }: UpdatePipelineRequest,
    opts?: OperationOpts
  ): Observable<PipelineUpdateModel | AjaxResponse<PipelineUpdateModel>> {
    throwIfNullOrUndefined(id, 'id', 'updatePipeline');
    throwIfNullOrUndefined(updatePipelineRequestModel, 'updatePipelineRequestModel', 'updatePipeline');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
    };

    return this.request<PipelineUpdateModel>(
      {
        url: '/pipelines/{id}'.replace('{id}', encodeURI(id)),
        method: 'PATCH',
        headers,
        body: updatePipelineRequestModel,
      },
      opts?.responseOpts
    );
  }
}
