import { css } from '@emotion/css';
import {
  Button,
  Collapse,
  Field,
  Icon,
  InlineField,
  Input,
  LinkButton,
  ReactMonacoEditor,
  Select,
  Stack,
} from '@grafana/ui';
import { GitHubContext } from 'contexts/github';
import { useFetchBranches, useFetchFileContents, useRepoInfo } from 'hooks';
import React, { useState, useContext, useMemo, useEffect } from 'react';
import { NullID, PLUGIN_ID } from '../../shared/constants';
import { SigmaRulesContext } from '../../stores/sigmarules';
import { RuleContent } from 'shared/requests/conversions';
import { GenericModal } from 'components/GenericModal';

export interface SigmaRuleDisplayProps {
  filename: string;
  content: RuleContent;
  owner?: string;
  repo?: string;
  repoLicense?: string;
  gitRef?: string;

  onChange?(filename: string, content: RuleContent): void;

  onRemove?(filename: string): void;
}

export const SigmaRuleDisplay = (props: SigmaRuleDisplayProps) => {
  const [filename, repoId] = useMemo(() => {
    let fname = props.filename.split('/');
    let repoId = '';
    if (fname.length > 1 && fname[0].match(/^[a-fA-F0-9]{8}-([a-fA-F0-9]{4}-){3}[a-fA-F0-9]{12}$/)) {
      repoId = fname.shift()!;
    }
    return [fname.join('/'), repoId] as const;
  }, [props.filename]);
  const [sourceRepo] = useRepoInfo(repoId);

  const [open, setOpen] = useState(true);
  const [currentRef, setCurrentRef] = useState(props.gitRef ?? sourceRepo.defaultBranch);
  const [branches] = useFetchBranches(props.filename, 'grafana-detect');
  const [content, loadingContent, id, setContent, updateRef, setId] = useFetchFileContents(
    sourceRepo.defaultBranch || '',
    props.content,
    props.filename,
    'grafana-detect'
  );
  const [modalLicense, setModalLicense] = useState(false);
  const githubConfig = useContext(GitHubContext);
  const {
    data: { createResponse },
    operations: { createWithArgs },
  } = useContext(SigmaRulesContext);

  useEffect(() => {
    if (!currentRef && branches.length) {
      setCurrentRef(branches[0]);
    }
  }, [currentRef, branches]);

  useEffect(() => {
    props.onChange &&
      props.onChange(props.filename, {
        ...props.content,
        content: content,
        ref: currentRef,
        ruleId: id,
      });
  }, [content, currentRef, props, id]);

  // We want to know when the rule ID is set for __this__ rule file. In order to do that, we check for creation events firing
  // that match the filename that we're expecting.
  useEffect(() => {
    createResponse.subscribe({
      next: (val) => {
        if (`${NullID}/${val.filename}` === props.filename) {
          setId(val.id);
        }
      },
    });
  }, [createResponse, props.filename, setId]);

  let owner = props.owner ?? sourceRepo.owner;
  let repo = props.repo ?? sourceRepo.repo;

  return (
    <Collapse
      label={
        <div style={{ textAlign: 'left', overflowWrap: 'anywhere' }}>
          <Icon name={props.content.origin === 'custom' ? 'file-blank' : props.content.origin} />
          <div style={{ width: '5px', display: 'inline-block' }} />
          {filename}
        </div>
      }
      isOpen={open}
      onToggle={() => {
        setOpen(!open);
      }}
      collapsible
    >
      <div>
        <InlineField label="Filename" grow disabled>
          <Input
            value={filename}
            suffix={
              // FIXME: using repoId as origin is currently always github
              props.content.origin === 'custom' || repoId === NullID ? (
                <LinkButton
                  href={`/a/${PLUGIN_ID}/sigma/ruleEditor?filename=${filename}`}
                  variant="secondary"
                  fill="text"
                  target="_blank"
                  icon="eye"
                />
              ) : props.content.origin === 'github' ? (
                <LinkButton
                  href={new URL([owner, repo, 'blob', currentRef, filename].join('/'), 'https://github.com').toString()}
                  variant="secondary"
                  fill="text"
                  target="_blank"
                  icon="eye"
                />
              ) : null
            }
            className={css('div { padding: 0; }')}
          />
        </InlineField>
        {props.content.origin === 'github' ? (
          <>
            {sourceRepo.license ? (
              <InlineField label="License" grow>
                {sourceRepo.owner.toLowerCase() === 'sigmahq' && sourceRepo.repo.toLowerCase() === 'sigma' ? (
                  <>
                    <GenericModal
                      title="License"
                      message={sourceRepo.license?.text || 'No license found'}
                      isModalOpen={modalLicense}
                      hideModal={() => setModalLicense(false)}
                    />

                    <Button variant="secondary" onClick={() => setModalLicense(true)}>
                      Detection Rule License
                    </Button>
                  </>
                ) : (
                  <LinkButton
                    variant="secondary"
                    href={
                      sourceRepo.license.html_url ||
                      sourceRepo.license.url ||
                      new URL(
                        [sourceRepo.owner, sourceRepo.repo, 'blob', currentRef, 'LICENSE'].join('/'),
                        'https://github.com'
                      ).toString()
                    }
                    target="_blank"
                  >
                    {sourceRepo.license.name}
                  </LinkButton>
                )}
              </InlineField>
            ) : null}
            {props.content.origin === 'github' ? (
              <InlineField
                label="Reference"
                grow
                tooltip="Grafana Detect will show at most, the 30 most recent commits where this file has changed"
              >
                <Select
                  onChange={(e) => {
                    setCurrentRef(e.value || '');
                    updateRef(e.value || sourceRepo.defaultBranch || '');
                  }}
                  value={currentRef}
                  options={[
                    ...branches.map((e, idx) => ({
                      label: e.match(/^[a-fA-F0-9]{40}$/)
                        ? idx === 0
                          ? sourceRepo.defaultBranch
                          : e.substring(0, 7)
                        : e.match(/^[a-fA-F0-9]{8}(-[a-fA-F0-9]{4}){3}-[a-fA-F0-9]{12}$/)
                        ? `Version ${branches.length - idx} - ${e}`
                        : e,
                      value: e,
                    })),
                  ]}
                ></Select>
              </InlineField>
            ) : null}
          </>
        ) : null}
        <Field label="Sigma Rule" description="The rule to convert">
          <ReactMonacoEditor
            height={300}
            value={content}
            options={{
              minimap: { enabled: false },
              wordWrap: 'on',
              language: 'yaml',
            }}
            loading="Loading Rule Content..."
            language="yaml"
            onChange={(v) => {
              if (!v) {
                return;
              }
              setContent(v);
              if (props.onChange && !loadingContent) {
                props.onChange(props.filename, { content: v, origin: 'custom', ruleId: id });
              }
            }}
          />
        </Field>
      </div>
      <div>
        <Stack direction="row">
          {props.content.origin === 'custom' ? (
            <Button
              icon="save"
              onClick={() => {
                createWithArgs(filename, content);
              }}
            >
              Save Changes
            </Button>
          ) : null}
          <Button
            variant="destructive"
            icon="trash-alt"
            onClick={() => {
              if (props.onRemove) {
                props.onRemove(props.filename);
              }
            }}
          >
            Remove Rule
          </Button>
        </Stack>
      </div>
    </Collapse>
  );
};
