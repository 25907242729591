import { getAppEvents } from '@grafana/runtime';
import { PLUGIN_ID } from 'shared/constants';
import { useCallback, useState, useMemo } from 'react';
import { firstValueFrom } from 'rxjs';
import { GithubRepoApi, Configuration as GithubRepoApiCfg, GithubRepoReadModel } from 'api/detect-service';
import { AppEvents } from '@grafana/data';
import { useTranslation } from 'react-i18next';

export const useCustomRepo = (repoId: string) => {
  const { t } = useTranslation();

  const [repo, setRepo] = useState<string>('');
  const [owner, setOwner] = useState<string>('');
  const [folder, setFolder] = useState<string>('');
  const [ref, setRef] = useState<string>('default');
  const [token, setToken] = useState<string>('');
  const [url, setUrl] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [lastCommit, setLastCommit] = useState<string>('');
  const [lastError, setLastError] = useState<string>('');

  const githubRepoApi = useMemo(
    () =>
      new GithubRepoApi(
        new GithubRepoApiCfg({
          basePath: `/api/plugins/${PLUGIN_ID}/resources/service/v1/db`,
        })
      ),
    []
  );

  const getCustomRepo = useCallback(() => {
    if (repoId === '') {
      setName('');
      setRepo('');
      setOwner('');
      setFolder('');
      setRef('default');
      setToken('');
      setUrl('');
      setLastCommit('');
      setLastError('');
    } else {
      retrieveRepository(repoId)
        .then((value) => {
          setName(value.name ?? '');
          setRepo(value.repo ?? '');
          setOwner(value.owner ?? '');
          setFolder(value.folder ?? '');
          setRef(value.ref ?? 'default');
          setToken(value.token ?? '');
          setLastCommit(value.last_commit ?? '');
          setLastError(value.last_error ?? '');
          setUrl(`https://www.github.com/${value.owner}/${value.repo}`);
        })
        .catch((reason) => {
          let status = reason.response.status ?? 'unknown';
          reason.message += ': ' + status;
          getAppEvents().publish({
            type: AppEvents.alertError.name,
            payload: [t('errors.repository.customRetrieve'), reason.message],
          });
          console.error(reason);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [repoId]);

  const saveCustomRepo = useCallback(async () => {
    if (owner === '' || repo === '' || name === '') {
      console.error('Missing required fields');
      getAppEvents().publish({
        type: AppEvents.alertError.name,
        payload: [t('errors.repository.customRepoSaveError'), 'Missing required fields'],
      });
      return;
    }
    let newRef = ref === 'default' ? '' : ref;
    if (repoId === '') {
      await firstValueFrom(
        githubRepoApi.createGithubRepo({
          createGithubRepoRequestModel: { name, owner, repo, folder, ref: newRef, token },
        })
      )
        .then((value) => {
          getAppEvents().publish({
            type: AppEvents.alertSuccess.name,
            payload: [t('errors.repository.customSaved'), 'Successfully saved custom repository'],
          });
        })
        .catch((reason) => {
          let status = reason.response.status ?? 'unknown';
          reason.message += ': ' + status;
          getAppEvents().publish({
            type: AppEvents.alertError.name,
            payload: [t('errors.repository.customRepoSaveError'), reason.message + '(' + status + ')'],
          });
        });
    } else {
      await firstValueFrom(
        githubRepoApi.updateGithubRepo({
          id: repoId,
          updateGithubRepoRequestModel: { name, owner, repo, folder, ref: newRef, token },
        })
      )
        .then((value) => {
          getAppEvents().publish({
            type: AppEvents.alertSuccess.name,
            payload: [t('errors.repository.customSaved'), 'Successfully saved custom repository'],
          });
        })
        .catch((reason) => {
          let status = reason.response.status ?? 'unknown';
          reason.message += ': ' + status;
          getAppEvents().publish({
            type: AppEvents.alertError.name,
            payload: [t('errors.repository.customRepoSaveError'), reason.message + '(' + status + ')'],
          });
          console.error(reason);
        });
    }
  }, [repoId, owner, repo, folder, ref, token, name, githubRepoApi, t]);

  const deleteCustomRepo = useCallback(async () => {
    if (repoId === '') {
      return;
    }
    await firstValueFrom(githubRepoApi.deleteGithubRepo({ id: repoId }))
      .then((value) => {
        getAppEvents().publish({
          type: AppEvents.alertSuccess.name,
          payload: [t('errors.repository.customDeleted'), 'Successfully deleted custom repository'],
        });
      })
      .catch((reason) => {
        let status = reason.response.status ?? 'unknown';
        reason.message += ': ' + status;
        getAppEvents().publish({
          type: AppEvents.alertError.name,
          payload: [t('errors.repository.customRepoDeleteError'), reason.message + '(' + status + ')'],
        });
        console.error(reason);
      });
  }, [repoId, githubRepoApi, t]);

  const updateUrl = useCallback((url: string) => {
    let regex = new RegExp('https?://(www.)?github.com/([^/]+)/(.*)', 'i');
    let match = regex.exec(url);
    if (match === null) {
      setOwner('');
      setRepo('');
      setUrl(url);
      return;
    }
    let owner = match[2];
    let repo = match[3];
    setOwner(owner);
    setRepo(repo);
    setUrl(`https://www.github.com/${owner}/${repo}`);
  }, []);

  async function retrieveRepository(uuid: string): Promise<GithubRepoReadModel> {
    return await firstValueFrom(githubRepoApi.readGithubRepo({ id: uuid }));
  }

  return [
    repo,
    owner,
    folder,
    url,
    ref,
    token,
    name,
    lastCommit,
    lastError,
    getCustomRepo,
    saveCustomRepo,
    deleteCustomRepo,
    updateUrl,
    setRef,
    setToken,
    setName,
    setFolder,
    setLastCommit,
    setLastError,
  ] as const;
};
