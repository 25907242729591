import { Button, FieldSet, Form, LoadingPlaceholder, Spinner } from '@grafana/ui';
import { Global, css } from '@emotion/react';
import React, { useContext, useEffect, useState } from 'react';
import { PipelineTitleInput } from './PipelineTitleInput';
import { PipelineContentInput } from './PipelineContentInput';
import { useValidate } from 'hooks/pipelines/useValidate';
import { PipelineContext } from 'stores/pipelines';
import { useQueryParams } from 'hooks/useQueryParams';
import { CreatePipelineRequest, CreatePipelineResponse } from 'shared/requests/pipelines';
import { getAppEvents } from '@grafana/runtime';
import { AppEvents } from '@grafana/data';
import { useTranslation } from 'react-i18next';

interface PipelineFormCreateProps {
  allowNameChange?: boolean;
}

export const PipelineFormCreate = (props: PipelineFormCreateProps) => {
  const [createPipeline, setCreatePipeline] = useState<CreatePipelineResponse | null>(null);

  const { t } = useTranslation();

  const {
    loading,
    data: { create },
    operations,
  } = useContext(PipelineContext);
  const [validator] = useValidate();

  const [qs, setQS] = useQueryParams();

  useEffect(() => {
    create?.subscribe(setCreatePipeline);
  }, [create]);

  useEffect(() => {
    if (!createPipeline) {
      return;
    }
    qs.delete('create');
    qs.set('pipeline', createPipeline.id);
    setQS(qs);
  }, [createPipeline, qs, setQS]);

  if (loading) {
    return <LoadingPlaceholder text="Loading..." />;
  }

  return (
    <Form
      onSubmit={(data: CreatePipelineRequest) => {
        if (!validator(data.content)) {
          getAppEvents().publish({
            type: AppEvents.alertError.name,
            payload: [t('errors.sigma.invalidYaml'), 'The YAML provided is not a valid Sigma Pipeline'],
          });
          return;
        }
        operations.create(data);
      }}
      width="100%"
      style={{ maxWidth: '100%' }}
      label="New Pipeline"
      defaultValues={{
        name: 'New Pipeline',
        content: '',
      }}
      spellCheck
    >
      {({ register, errors, setValue, getValues }) => {
        register('content', {
          required: true,
        });
        register('name', {
          maxLength: 255,
          minLength: 1,
          required: true,
        });

        return (
          <>
            <Global
              styles={css`
                textarea {
                  font-family: Roboto Mono, monospace;
                  font-size: 14px;
                  overflow: auto;
                  word-break: break-word;
                }
              `}
            />
            <FieldSet label="New Pipeline">
              <PipelineTitleInput
                onChange={(e) => {
                  setValue('name', e);
                }}
                invalid={!!errors.name}
                error={errors.name?.message}
                value={getValues().name}
              />
              <PipelineContentInput
                onChange={(e) => {
                  setValue('content', e);
                }}
                invalid={!!errors.content}
                error={errors.content?.message}
                value={getValues().content}
              />
            </FieldSet>
            <Button type="submit" disabled={loading}>
              {loading ? <Spinner /> : null}Submit
            </Button>
          </>
        );
      }}
    </Form>
  );
};
