import {
  Filter,
  JsonExpressionParser,
  LabelParser,
  LineFilter,
  LogfmtExpressionParser,
  LogfmtParser,
  Matcher,
  parser,
  PipelineExpr,
  Selector,
  String as TOKString,
} from '@grafana/lezer-logql';
import { SyntaxNode, SyntaxNodeRef } from '@lezer/common';
import { FilterNode, LokiFilterMatch, LokiLineFilter, LokiConfiguration, LokiParserFilter } from './types';
import { DataSourceParserConfig } from '../../../DataSourceConfiguration';

const filter: Array<number> = [
  Matcher,
  LabelParser,
  LogfmtParser,
  LogfmtExpressionParser,
  JsonExpressionParser,
  LineFilter,
  PipelineExpr,
];

export const render = (config: LokiConfiguration): string => {
  if (!config) {
    return '';
  }
  if ('selector' in config) {
    let filters = JSON.parse(config.selector) as Array<LokiFilterMatch>;
    if (!Array.isArray(filters)) {
      return '{job=~".+"}';
    }
    return `{${filters
      .map((f) => new LokiFilterMatch(f.label, f.op, f.value))
      .map((f) => f.raw)
      .join(',')}}${config.extra ? ' ' : ''}${config.extra}`;
  }
  if ('filters' in config) {
    let filters: Array<{ label: string; op: string; value: string }> = JSON.parse(config.filters);
    let parserConfig: DataSourceParserConfig = JSON.parse(config.parserConfig);
    if (filters.filter((f) => f.label && f.value).length === 0) {
      return '{job=~".+"}';
    }

    let filterText = filters.map((f) => `${f.label}${f.op}"${f.value}"`).join(',');
    let parserText = '';
    if (parserConfig && parserConfig.parser && parserConfig.parser.value) {
      parserText = `${parserConfig.parser ? ' | ' : ''}${parserConfig.parser?.value}${
        parserConfig.params ? ' ' + parserConfig.params : ''
      }${parserConfig.additionalExpr ? ' | ' + parserConfig.additionalExpr : ''}`;
    }

    return `{${filterText}}${parserText}`;
  }

  return '';
};

export const parse = (query: string): LokiConfiguration => {
  let p = parser.parse(query);

  let nodes: Array<SyntaxNode> = [];

  p.iterate({
    enter(node: SyntaxNodeRef): boolean | void {
      if (filter.includes(node.type.id)) {
        nodes.push(node.node);
      }
    },
  });

  let extra = nodes.find((n) => n.type.id === PipelineExpr);

  return {
    selector: JSON.stringify(nodes.filter(filterSelectors).map((n) => processMatch(query, n))),
    extra: extra ? query.slice(extra.from, extra.to) : '',
  } as LokiConfiguration;
};

const filterSelectors = (node: SyntaxNode) => {
  return hasParentInSelection(node.node);
};

// We only want the matchers that are a part of the selector so we filter out other matchers.
const hasParentInSelection = (node: SyntaxNode): boolean => {
  if (node.type.id === Selector) {
    return true;
  }

  if (node.parent) {
    return hasParentInSelection(node.parent);
  }

  return false;
};

const processMatch = (query: string, inputNode: SyntaxNode): FilterNode | undefined => {
  switch (inputNode.type.id) {
    case Matcher:
      return processMatcher(query, inputNode);
    case LineFilter:
      return processLineFilter(query, inputNode);
    default:
      return undefined;
  }
};

const processLineFilter = (query: string, inputNode: SyntaxNode): FilterNode | undefined => {
  let _query = query.substring(inputNode.from, inputNode.to);

  let op = '';
  let value = '';

  inputNode.toTree().iterate({
    enter: (node) => {
      switch (node.node.type.id) {
        case LineFilter:
          return;
        case Filter:
          op = _query.substring(node.node.from, node.node.to);
          return;
        case TOKString:
          value = _query.substring(node.node.from, node.node.to);
          return;
      }
    },
  });

  return new LokiLineFilter(op, value);
};

const processMatcher = (query: string, inputNode: SyntaxNode): FilterNode | undefined => {
  if (inputNode.type.id !== Matcher) {
    return undefined;
  }

  let _query = query.substring(inputNode.from, inputNode.to);

  const values: Array<string> = [];

  inputNode.toTree().iterate({
    enter: (node) => {
      // We only want to process the values of the matcher, not the matcher itself.
      if (node.node.type.id === Matcher) {
        return;
      }
      values.push(_query.substring(node.node.from, node.node.to));
    },
  });

  return new LokiFilterMatch(values[0], values[1], values[2]);
};
