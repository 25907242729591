import { Button, FieldSet, Form, Spinner, useStyles2 } from '@grafana/ui';
import { Global } from '@emotion/react';
import { css } from '@emotion/css';
import React, { useContext, useEffect, useState } from 'react';
import { PipelineTitleInput } from './PipelineTitleInput';
import { PipelineContentInput } from './PipelineContentInput';
import { useQueryParams } from 'hooks/useQueryParams';
import { useValidate } from 'hooks/pipelines/useValidate';
import { PipelineContext } from 'stores/pipelines';
import { CreatePipelineRequest, GetPipelineResponse } from 'shared/requests/pipelines';
import { StatefulDeletePipelineModal } from './DeletePipelineModal';
import { getAppEvents } from '@grafana/runtime';
import { AppEvents } from '@grafana/data';
import { useTranslation } from 'react-i18next';

const getStyles = () => ({
  buttonRow: css`
    display: flex;
    & > button {
      margin: 0.5rem;
    }
  `,
  divider: css`
    flex-grow: grow;
    width: 100%;
  `,
  modal: css`
    overflow-wrap: break-word;
  `,
});

export const PipelineFormUpdate = () => {
  const [qs, setQS] = useQueryParams();
  const [del, setDel] = useState(false);

  const { t } = useTranslation();

  const [pipeline, setPipeline] = useState<GetPipelineResponse>({} as GetPipelineResponse);

  const {
    data: { get: getPipeline, delete: deletePipeline },
    operations: { get: doGetPipeline, update: doUpdatePipeline },
    loading,
    remote,
  } = useContext(PipelineContext);
  const styles = useStyles2(getStyles);
  const [validator] = useValidate();

  useEffect(() => {
    deletePipeline?.subscribe((id) => {
      if (id === pipeline.id) {
        qs.delete('pipeline');
        setQS(qs);
      }
    });
  }, [deletePipeline, pipeline, qs, setQS]);

  useEffect(() => {
    doGetPipeline(qs.get('pipeline') ?? '');
    getPipeline?.subscribe(setPipeline);
  }, [qs, getPipeline, doGetPipeline, remote]);

  if (loading || !pipeline) {
    return <Spinner />;
  }

  return (
    <>
      <StatefulDeletePipelineModal
        isOpen={del}
        dismiss={(deleted: boolean) => {
          if (deleted) {
            qs.delete('pipeline');
            setQS(qs);
          }
          setDel(false);
        }}
      />
      <Form
        onSubmit={(data: CreatePipelineRequest) => {
          if (!validator(data.content)) {
            getAppEvents().publish({
              type: AppEvents.alertError.name,
              payload: [t('errors.sigma.invalidYaml'), 'The YAML provided is not a valid Sigma Pipeline'],
            });
            return;
          }
          doUpdatePipeline(pipeline.id, data);
        }}
        width="100%"
        style={{ maxWidth: '100%' }}
        label="Edit Pipeline"
        defaultValues={{
          name: pipeline.name,
          content: pipeline.content,
        }}
        spellCheck
      >
        {({ register, errors, setValue, getValues }) => {
          register('content', {
            required: true,
          });
          register('name', {
            maxLength: 255,
            minLength: 1,
            required: true,
          });

          return (
            <>
              <Global
                styles={css`
                  textarea {
                    font-family: Roboto Mono, monospace;
                    font-size: 14px;
                    overflow: auto;
                    word-break: break-word;
                  }
                `}
              />
              <FieldSet label="Edit Pipeline">
                <PipelineTitleInput
                  onChange={(e) => {
                    setValue('name', e);
                  }}
                  invalid={!!errors.name}
                  error={errors.name?.message}
                  value={getValues().name}
                />
                <PipelineContentInput
                  onChange={(e) => {
                    setValue('content', e);
                  }}
                  invalid={!!errors.content}
                  error={errors.content?.message}
                  value={getValues().content}
                />
              </FieldSet>
              <div className={styles.buttonRow}>
                <Button type="submit" disabled={loading}>
                  {loading ? <Spinner /> : null}Save
                </Button>
                <div className={styles.divider} />
                <Button variant="destructive" onClick={() => setDel(true)}>
                  Delete Pipeline
                </Button>
              </div>
            </>
          );
        }}
      </Form>
    </>
  );
};
