import React, { useContext, useEffect } from 'react';
import { Button, EmptySearchResult, InlineField, InlineFieldRow, LoadingPlaceholder } from '@grafana/ui';
import { SigmaRuleDisplay } from './SigmaRuleDisplay';
import { RulePointer } from 'hooks';
import { VerticalContainer } from 'components/containers/Vertical';
import { SigmaCustomRuleModalButton } from './SigmaCustomRuleModal';
import { SigmaGitHubRuleImportModalButton } from './SigmaGitHubRuleImportModal';
import { ConversionContext } from 'stores/conversion';
import { RuleContent, Rules } from 'shared/requests/conversions';

interface ConversionEditorProps {
  setRules: (rules: Rules) => void;
  requestRules: (rules: Array<RulePointer>) => void;
  appendRule: (filename: string, content: RuleContent) => void;
  rules: Rules;
  loading: boolean;
}

interface StatefulConversionEditorProps {
  requestRules: (rules: Array<RulePointer>) => void;
  loading: boolean;
}

export const ConversionEditor = (props: ConversionEditorProps) => {
  const { rules, loading, setRules, requestRules, appendRule } = props;
  return (
    <VerticalContainer breakPoint={1200} defaultOpen label="Rules">
      <InlineFieldRow>
        <InlineField>
          <SigmaGitHubRuleImportModalButton onImport={(rules) => requestRules(rules)} />
        </InlineField>
        <InlineField>
          <SigmaCustomRuleModalButton
            onCreate={(filename) => setRules({ ...rules, [filename]: { content: '', origin: 'custom' } })}
          />
        </InlineField>
        <InlineField disabled={Object.keys(rules).length <= 0}>
          <Button variant="destructive" icon="trash-alt" onClick={() => setRules({})}>
            Clear All
          </Button>
        </InlineField>
      </InlineFieldRow>
      <div
        style={{
          overflowY: 'auto',
          width: '100%',
          maxHeight: '1200px',
        }}
      >
        {Object.keys(rules).length === 0 && !loading ? (
          <EmptySearchResult>No rules selected yet...</EmptySearchResult>
        ) : null}
        {loading ? <LoadingPlaceholder text={'Loading Rules...'} /> : null}
        {Object.keys(rules).map((e) => {
          return (
            <SigmaRuleDisplay
              key={`rule-${e}`}
              filename={e}
              repo={rules[e].repo}
              owner={rules[e].owner}
              content={props.rules[e]}
              gitRef={rules[e].ref ?? rules[e].ruleId}
              onChange={appendRule}
              onRemove={(file) => {
                let currentRules = Object.assign({}, rules);
                delete currentRules[file];
                setRules(currentRules);
              }}
            />
          );
        })}
      </div>
    </VerticalContainer>
  );
};

export const StatefulConversionEditor = (props: StatefulConversionEditorProps) => {
  const [rules, setRules] = React.useState<Rules>({});

  const {
    data: { rules: stateRules },
    operations: { setRules: doSetRules, appendRule: doAppendRule },
  } = useContext(ConversionContext);

  useEffect(() => {
    stateRules.subscribe(setRules);
  }, [stateRules]);

  return <ConversionEditor {...props} setRules={doSetRules} appendRule={doAppendRule} rules={rules} />;
};
