import React from 'react';
import {
  CustomVariable,
  SceneComponentProps,
  sceneGraph,
  SceneObjectBase,
  SceneObjectState,
  VariableDependencyConfig,
  VariableValueSingle,
} from '@grafana/scenes';
import { Button, FieldSet, PanelChrome } from '@grafana/ui';
import {
  DataSourceConfigApi,
  DataSourceConfigReadModel,
  ListDataSourceConfigPipelines200ResponseModel,
} from '../../../api/detect-service';
import { DetectServiceConfig } from '../../../shared/apiConfigs';
import { AppEvents, DataSourceApi, LoadingState } from '@grafana/data';
import { DetectScenesVariables } from '../../variables';
import { parse } from '../../../components/scenes/logsource/queries/loki';
import { LokiConfiguration } from '../../../components/scenes/logsource/queries/types';
import { CustomPipelines } from '../panel-components/editor/customPipelines';
import { ExtraArgs } from '../panel-components/editor/extraArgs';
import { NameField } from '../panel-components/editor/name';
import { Datasource } from '../panel-components/editor/datasource';
import { QueryEditor as QueryEditor2 } from '../panel-components/editor/queryEditor';
import { DetectEvent, DetectEventTypes } from '../../events';
import { LabelList } from '../panel-components/editor/labellist';
import { getAppEvents, getDataSourceSrv, RefreshEvent } from '@grafana/runtime';
import { map } from 'rxjs';
import { NullID } from '../../../shared/constants';

interface LogSourceEditorPanelState extends SceneObjectState {}

interface LogSourceEditorPanelStateInternal extends LogSourceEditorPanelState {
  datasource?: DataSourceConfigReadModel;
  dirty: boolean;
  tags: Array<string>;

  logsource: Array<string>;

  logsourceName: NameField;
  logsourceDatasource: Datasource;
  logsourceQuery: QueryEditor2;
  logsourceTagList: LabelList;
  customPipelines: CustomPipelines;
  extraArgs: ExtraArgs;
}

export class LogSourceEditorPanelObject extends SceneObjectBase<LogSourceEditorPanelStateInternal> {
  private logsourceApi: DataSourceConfigApi = new DataSourceConfigApi(DetectServiceConfig);

  protected static Component = Renderer;

  _variableDependency = new VariableDependencyConfig(this, {
    variableNames: [DetectScenesVariables.LogSource],
    statePaths: ['logsource'],
    onReferencedVariableValueChanged: (variable) => {
      switch (variable.state.name) {
        case DetectScenesVariables.LogSource:
          this.onLogSourceChange();
          break;
        default:
          break;
      }
    },
  });

  constructor(args: Partial<LogSourceEditorPanelState>) {
    super({
      ...args,
      dirty: false,
      tags: [],
      logsource: ['$' + DetectScenesVariables.LogSource],
      logsourceName: new NameField({}),
      logsourceDatasource: new Datasource({}),
      logsourceQuery: new QueryEditor2({}),
      logsourceTagList: new LabelList({}),
      customPipelines: new CustomPipelines({}),
      extraArgs: new ExtraArgs({}),
    });
    this.addActivationHandler(this.activationHandler.bind(this));
  }

  public activationHandler() {
    this.onLogSourceChange();
    let unsub = this.getRoot().subscribeToEvent(DetectEvent, (evt) => {
      switch (evt.payload.type) {
        case DetectEventTypes.EditorCreate:
          this.createLogSource();
          break;
        case DetectEventTypes.EditorUpdate:
          this.updateLogSource();
          break;
      }
    });

    return () => {
      unsub.unsubscribe();
    };
  }

  public onLogSourceChange() {
    const logsourceVar = sceneGraph.lookupVariable(DetectScenesVariables.LogSource, this) as CustomVariable;
    const logsource = logsourceVar.getValue() as string;

    const logsourceDatasource = sceneGraph.lookupVariable(
      DetectScenesVariables.LogSourceDataSource,
      this
    ) as CustomVariable;
    const logsourceName = sceneGraph.lookupVariable(DetectScenesVariables.LogSourceName, this) as CustomVariable;
    if (logsource === '') {
      return;
    }
    const extraArgs = sceneGraph.lookupVariable(DetectScenesVariables.ExtraArgs, this) as CustomVariable;
    const customPipelines = sceneGraph.lookupVariable(DetectScenesVariables.CustomPipelines, this) as CustomVariable;

    this.logsourceApi
      .listDataSourceConfigPipelines({ id: logsource })
      .pipe(
        map<ListDataSourceConfigPipelines200ResponseModel, Array<string>>((response) => {
          return response.data.map((pipeline) => pipeline.id);
        })
      )
      .subscribe({
        next: (value) => {
          customPipelines.changeValueTo(value);
        },
        error: (error) => {
          customPipelines.changeValueTo([]);
          getAppEvents().publish({
            type: AppEvents.alertError.name,
            payload: ['Failed to load Pipelines', error.toString()],
          });
        },
      });

    this.logsourceApi.readDataSourceConfig({ id: logsource }).subscribe({
      next: (value) => {
        logsourceDatasource.changeValueTo(value.uid ?? logsourceDatasource.getValueText());
        logsourceName.changeValueTo(value.name ?? logsourceName.getValueText());
        if ('selector' in value.configuration) {
          this.publishEvent(
            new DetectEvent({
              type: DetectEventTypes.EditorUpdateQuery,
              payload: value.configuration as LokiConfiguration,
            }),
            true
          );
        }

        if ('extraArgs' in value.configuration) {
          extraArgs.changeValueTo(value.configuration.extraArgs);
        } else {
          extraArgs.changeValueTo('');
        }
      },
      error: (error) => {
        logsourceVar.changeValueTo('');
        logsourceDatasource.changeValueTo('');
        logsourceName.changeValueTo('');
        extraArgs.changeValueTo('');
        this.publishEvent(
          new DetectEvent({
            type: DetectEventTypes.EditorUpdateQuery,
            payload: '{job=~".*"}',
          })
        );
        getAppEvents().publish({
          type: AppEvents.alertError.name,
          payload: ['Failed to load Log Source', error.toString()],
        });
      },
    });
  }

  public fetchLabels() {
    this.publishEvent(
      new DetectEvent({
        type: DetectEventTypes.EditorFetchLabels,
        payload: { withParser: true },
      }),
      true
    );
  }

  public updateLogSource() {
    const appEvents = getAppEvents();
    const logsource = sceneGraph.lookupVariable(DetectScenesVariables.LogSource, this) as CustomVariable;

    this.populateData(false).then((data) => {
      this.logsourceApi
        .updateDataSourceConfig({
          id: logsource.getValueText(),
          updateDataSourceConfigRequestModel: data,
        })
        .subscribe({
          next: (value) => {
            logsource.changeValueTo(value.id);
            appEvents.publish({
              type: AppEvents.alertSuccess.name,
              payload: ['Updated Log Source', value.id],
            });
          },
          error: (error) => {
            appEvents.publish({
              type: AppEvents.alertError.name,
              payload: ['Error' + ': ' + error.status + ' (Failed to update log source)'],
            });
          },
        });
    });
  }

  public createLogSource() {
    const appEvents = getAppEvents();
    const logsource = sceneGraph.lookupVariable(DetectScenesVariables.LogSource, this) as CustomVariable;

    this.populateData().then((data) => {
      this.logsourceApi
        .createDataSourceConfig({
          createDataSourceConfigRequestModel: data,
        })
        .subscribe({
          next: (value) => {
            logsource.changeValueTo(value.id);
            appEvents.publish({
              type: AppEvents.alertSuccess.name,
              payload: ['Created New Log Source', value.id],
            });
          },
          error: (error) => {
            appEvents.publish({
              type: AppEvents.alertError.name,
              payload: ['Error' + ': ' + error.status + ' (Cannot create Log Source at this time)'],
            });
          },
        });
    });
  }

  public populateData(create = true) {
    const logsourceDs = sceneGraph.lookupVariable(DetectScenesVariables.LogSourceDataSource, this) as CustomVariable;
    const logsourceName = sceneGraph.lookupVariable(DetectScenesVariables.LogSourceName, this) as CustomVariable;
    const logsourceQuery = sceneGraph.lookupVariable(DetectScenesVariables.LogSourceQuery, this) as CustomVariable;
    const extraArgs = sceneGraph.lookupVariable(DetectScenesVariables.ExtraArgs, this) as CustomVariable;
    const customPipelines = sceneGraph.lookupVariable(DetectScenesVariables.CustomPipelines, this) as CustomVariable;

    const pipelinesValue = customPipelines.getValue() as Array<VariableValueSingle>;

    let customPipelinesValue = create ? [] : [NullID];
    customPipelinesValue.push(...pipelinesValue.map((pipeline) => pipeline.toString()));

    return getDataSourceSrv()
      .get({ uid: logsourceDs.getValueText() })
      .then((ds: DataSourceApi) => {
        return Promise.resolve({
          type: ds.type,
          uid: ds.uid,
          configuration: Object.assign({}, parse(logsourceQuery.getValueText()), {
            extraArgs: extraArgs.getValueText(),
          }),
          name: logsourceName.getValueText(),
          pipelines: customPipelinesValue,
        });
      });
  }
}

function Renderer({ model }: SceneComponentProps<LogSourceEditorPanelObject>) {
  const { tags, logsourceName, logsourceDatasource, logsourceQuery, logsourceTagList, customPipelines, extraArgs } =
    model.useState();

  return (
    <div style={{ flex: 1 }}>
      <PanelChrome
        title="Log Source Editor"
        loadingState={LoadingState.Done}
        actions={
          <Button size="sm" variant="secondary" onClick={model.fetchLabels.bind(model)}>
            Fetch Parser
          </Button>
        }
      >
        <FieldSet>
          <logsourceName.Component model={logsourceName} />
          <logsourceDatasource.Component model={logsourceDatasource} />
          <logsourceQuery.Component model={logsourceQuery} />
          <logsourceTagList.Component model={logsourceTagList} />
          <customPipelines.Component model={customPipelines} />
          <extraArgs.Component model={extraArgs} />
        </FieldSet>
      </PanelChrome>
    </div>
  );
}
