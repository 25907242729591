/**
 * Shared types that can be reused by Loki and other data sources
 */

import { ComponentType } from 'react';

import { BusEventWithPayload, DataSourceApi, QueryResultMetaStat, RegistryItem, SelectableValue } from '@grafana/data';

export interface QueryBuilderLabelFilter {
  label: string;
  op: string;
  value: string;
}

export interface QueryBuilderOperation {
  id: string;
  params: Array<QueryBuilderOperationParamValue>;
}

export interface QueryWithOperations {
  operations: Array<QueryBuilderOperation>;
}

export interface QueryBuilderOperationDef<T = any> extends RegistryItem {
  documentation?: string;
  params: Array<QueryBuilderOperationParamDef>;
  defaultParams: Array<QueryBuilderOperationParamValue>;
  category: string;
  hideFromList?: boolean;
  alternativesKey?: string;
  /** Can be used to control operation placement when adding a new operations, lower are placed first */
  orderRank?: number;
  renderer: QueryBuilderOperationRenderer;
  addOperationHandler: QueryBuilderAddOperationHandler<T>;
  paramChangedHandler?: QueryBuilderOnParamChangedHandler;
  explainHandler?: QueryBuilderExplainOperationHandler;
  changeTypeHandler?: (op: QueryBuilderOperation, newDef: QueryBuilderOperationDef<T>) => QueryBuilderOperation;
}

export type QueryBuilderAddOperationHandler<T> = (
  def: QueryBuilderOperationDef,
  query: T,
  modeller: VisualQueryModeller
) => T;

export type QueryBuilderExplainOperationHandler = (op: QueryBuilderOperation, def: QueryBuilderOperationDef) => string;

export type QueryBuilderOnParamChangedHandler = (
  index: number,
  operation: QueryBuilderOperation,
  operationDef: QueryBuilderOperationDef
) => QueryBuilderOperation;

export type QueryBuilderOperationRenderer = (
  model: QueryBuilderOperation,
  def: QueryBuilderOperationDef,
  innerExpr: string
) => string;

export type QueryBuilderOperationParamValue = string | number | boolean;

export interface QueryBuilderOperationParamDef {
  name: string;
  type: 'string' | 'number' | 'boolean';
  options?: Array<string> | Array<number> | Array<SelectableValue<string>>;
  hideName?: boolean;
  restParam?: boolean;
  optional?: boolean;
  placeholder?: string;
  description?: string;
  minWidth?: number;
  editor?: ComponentType<QueryBuilderOperationParamEditorProps>;
  runQueryOnEnter?: boolean;
}

export interface QueryBuilderOperationEditorProps {
  operation: QueryBuilderOperation;
  index: number;
  query: any;
  datasource: DataSourceApi;
  queryModeller: VisualQueryModeller;
  onChange: (index: number, update: QueryBuilderOperation) => void;
  onRemove: (index: number) => void;
}

export interface QueryBuilderOperationParamEditorProps {
  value?: QueryBuilderOperationParamValue;
  paramDef: QueryBuilderOperationParamDef;
  /** Parameter index */
  index: number;
  operation: QueryBuilderOperation;
  operationIndex: number;
  query: any;
  datasource: DataSourceApi;
  onChange: (index: number, value: QueryBuilderOperationParamValue) => void;
  onRunQuery: () => void;
}

export enum QueryEditorMode {
  Code = 'code',
  Builder = 'builder',
}

export interface VisualQueryModeller {
  getOperationsForCategory(category: string): Array<QueryBuilderOperationDef>;
  getAlternativeOperations(key: string): Array<QueryBuilderOperationDef>;
  getCategories(): Array<string>;
  getOperationDef(id: string): QueryBuilderOperationDef | undefined;
}

export interface QueryResult {
  stats?: Array<QueryResultMetaStat>;
  query: string;
  matched: number;
}

export interface Argument {
  annotation: string;
  default_value: any;
  type: string;
}

export interface TransformedArgument {
  name: string;
  type: string;
  defaultValue: any;
  currentValue: any;
}

export interface Metadata {
  message?: string;
  rule_id?: string;
  processing_pipeline?: string;
}

export interface ErrorDetail {
  reason: string;
  domain: string;
  metadata?: Metadata;
}

export interface StoreConversionErrorProps {
  status: string;
  message: string;
  error_details: Array<ErrorDetail>;
}

export interface ErrorResponsePayload {
  props: StoreConversionErrorProps;

  isOpen: boolean;
  onDismiss?: () => void;
}

export class EnrichedErrorEvent extends BusEventWithPayload<StoreConversionErrorProps> {
  static type = 'enriched-error-event';
}
