import { getAppEvents, getBackendSrv } from '@grafana/runtime';
import { PLUGIN_ID } from 'shared/constants';
import { useCallback, useContext, useMemo, useState } from 'react';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import { GithubRepoApi, Configuration as GithubRepoApiCfg, GithubRepoReadModel } from 'api/detect-service';
import { AppEvents } from '@grafana/data';
import { useTranslation } from 'react-i18next';

export const useMainRepo = () => {
  const { t } = useTranslation();
  const [repo, setRepo] = useState<string>('');
  const [owner, setOwner] = useState<string>('');
  const [folder, setFolder] = useState<string>('');
  const [ref, setRef] = useState<string>('default');
  const [repoId, setRepoId] = useState<string>('');
  const [auth, setAuth] = useState<boolean>(false);
  const [licensetext, setLicensetext] = useState<string>('');
  const [lastCommit, setLastCommit] = useState<string>('');
  const [lastError, setLastError] = useState<string>('');

  const githubRepoApi = useMemo(
    () =>
      new GithubRepoApi(
        new GithubRepoApiCfg({
          basePath: `/api/plugins/${PLUGIN_ID}/resources/service/v1/db`,
        })
      ),
    []
  );

  function areWeTestingWithJest() {
    if (typeof process === 'undefined') {
      return false;
    }
    return process.env.JEST_WORKER_ID !== undefined;
  }

  const getMainRepo = useCallback(() => {
    // Returning default values if we are testing with Jest
    if (areWeTestingWithJest()) {
      return;
    }

    retrieveMainRepositoryId()
      .then((id) => {
        if (id === '') {
          let e = new Error();
          e.stack = undefined;
          e.message = 'Cannot find main repository uuid';
          getAppEvents().publish({
            type: AppEvents.alertError.name,
            payload: [t('errors.repository.mainRetrieve'), e.message],
          });
          return;
        }
        retrieveRepository(id)
          .then((value) => {
            setRepo(value.repo ?? '');
            setOwner(value.owner ?? '');
            setFolder(value.folder ?? '');
            setRef(value.ref ?? 'default');
            setRepoId(value.id ?? '');
            setLastCommit(value.last_commit ?? '');
            setLastError(value.last_error ?? '');
            setLicensetext(value.license ?? 'No license found');
            setAuth(typeof value.token !== 'undefined' && value.token !== '');
          })
          .catch((e): any => {
            getAppEvents().publish({
              type: AppEvents.alertError.name,
              payload: [t('errors.repository.mainRetrieve'), e],
            });
          });
      })
      .catch((e): any => {
        getAppEvents().publish({
          type: AppEvents.alertError.name,
          payload: [t('errors.repository.mainRetrieveUuid'), e],
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function retrieveRepository(uuid: string): Promise<GithubRepoReadModel> {
    return await firstValueFrom(githubRepoApi.readGithubRepo({ id: uuid }));
  }

  async function retrieveMainRepositoryId(): Promise<string> {
    let listRepos = await firstValueFrom(githubRepoApi.listGithubRepo({ page: 1, itemsPerPage: 1000 }));

    let uuid = '';
    listRepos.data.forEach((d: any) => {
      if ('name' in d && d.name === 'Main repository') {
        uuid = d.id;
      }
    });

    return uuid;
  }

  return [repo, owner, folder, ref, auth, repoId, lastCommit, lastError, getMainRepo] as const;
};
