import { GrafanaTheme2, SelectableValue } from '@grafana/data';
import { ActionMeta, Field, getTagColor, MultiSelect, TagList, useStyles2, useTheme2 } from '@grafana/ui';
import React, { useContext, useEffect, useState } from 'react';
import { GetPipelineResponse, PipelineList } from 'shared/requests/pipelines';
import { PipelineContext } from 'stores';
import { GREY_COLOR_INDEX } from '../../shared/constants';
import { css } from '@emotion/css';

interface FieldCustomPipelineProps {
  // selectedPipeline is for setting the default selection (primarily for component testing, but could have use elsewhere)
  selectedPipeline?: Array<SelectableValue<string>>;
  onChange?: (pipeline: Array<SelectableValue<string>>, action: ActionMeta) => void;
}

export const FieldCustomPipeline = ({ selectedPipeline, onChange }: FieldCustomPipelineProps) => {
  const {
    data: { list: listData, getMulti, logSourcePipelines: logSourcePipelinesData },
    operations: { getMulti: doGetMulti, list: doList },
  } = useContext(PipelineContext);

  const [pipelines, setPipelines] = useState<PipelineList>({
    data: [],
    count: 0,
    page: 0,
    pageSize: 0,
    returned: 0,
  });

  const [logSourcePipelines, setLogSourcePipelines] = useState<Array<GetPipelineResponse>>([]);

  const [selected, setSelected] = useState<Array<SelectableValue<string>>>(selectedPipeline ?? []);

  const theme = useStyles2(classes);

  useEffect(() => {
    listData?.subscribe(setPipelines);
    getMulti?.subscribe((e) => {
      setSelected(e.map((e) => ({ label: e.name, value: e.id })));
      onChange?.(
        e.map((e) => ({ label: e.name, value: e.id })),
        { action: 'select-option', option: e }
      );
    });
    logSourcePipelinesData?.subscribe(setLogSourcePipelines);
  }, [listData, getMulti, onChange, logSourcePipelinesData]);

  useEffect(() => {
    doList(1);
  }, [doList]);

  return (
    <>
      <Field label="Custom pipeline" description="Use a custom pipeline">
        <MultiSelect
          options={[
            ...pipelines.data
              .filter((e) => !logSourcePipelines.map((e) => e.id).includes(e.id))
              .map((e) => ({
                label: e.name,
                value: e.id,
              })),
          ]}
          value={selected.filter((selection) => !logSourcePipelines.map((e) => e.id).includes(selection.value ?? ''))}
          onChange={(e, action) => {
            setSelected(e);
            doGetMulti(e.map((e) => e.value ?? ''));
            onChange?.(e, action);
          }}
        ></MultiSelect>
      </Field>
      <Field label="Logsource Custom pipelines" description="Custom pipelines provided by the logsource">
        <TagList
          getColorIndex={() => GREY_COLOR_INDEX}
          tags={logSourcePipelines.map((e) => e.name ?? '')}
          className={theme.tagList}
        />
      </Field>
    </>
  );
};

const classes = (theme: GrafanaTheme2) => {
  return {
    tagList: css`
      justify-content: flex-start;
      max-height: 6em;
      overflow-y: scroll;
      * {
        margin: 0 ${theme.spacing.x0_25};
      }
    `,
  };
};
