/**
 * This store will rarely, if ever, have user interaction, it is designed to provide
 * contextual information to the plugin about where it is running. This is in part
 * to determine whether we are running in a cloud environment or on premise. It also
 * provide information about whether or not the service is deployed.
 */

import { FetchResponse, getBackendSrv } from '@grafana/runtime';
import { load } from 'js-yaml';
import { uniqueId } from 'lodash';
import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { PLUGIN_ID, VAR_CONVERSION_ID } from 'shared/constants';
import {
  CreatePipelineRequest,
  CreatePipelineResponse,
  GetPipelineResponse,
  PipelineList,
  PipelineListItem,
} from 'shared/requests/pipelines';
import { CustomPipeline, ProcessingPipeline } from 'shared/requests/conversions';

import { Observable, BehaviorSubject, switchMap, from, map, zip, tap, timer, of, merge, forkJoin } from 'rxjs';
import { ConversionApi, DataSourceConfigApi } from '../api/detect-service';
import { useLocation } from 'react-router-dom';
import { DetectServiceConfig } from '../shared/apiConfigs';

export const PipelineContext = createContext<PipelinesState>({} as PipelinesState);

// Export the type of the state, this allows us to know what the state looks like elsewheres
export interface PipelinesState {
  loading: boolean;
  remote: Observable<boolean>;
  data: {
    page: number;
    list?: Observable<PipelineList>;
    create?: Observable<CreatePipelineResponse | null>;
    get?: Observable<GetPipelineResponse>;
    getMulti?: Observable<Array<GetPipelineResponse>>;
    delete?: Observable<string>;
    multiContent?: Observable<Array<CustomPipeline>>;
    logSourcePipelines?: Observable<Array<GetPipelineResponse>>;
  };
  operations: {
    list(page: number): void;
    create(request: CreatePipelineRequest): void;
    get(id: string): void;
    getMulti(ids: Array<string>): void;
    delete(id: string): void;
    update(id: string, request: CreatePipelineRequest): void;
    setLogSourcePipelines(ids: Array<string>): void;
    __setRemote(remote: boolean): void;
  };
}

export const PipelinesProvider = (props: { children: React.ReactNode }) => {
  const { search } = useLocation();

  const [loading, setLoading] = useState<boolean>(true);
  const remote = useMemo(() => new BehaviorSubject<boolean>(false), []);
  const [page, setPage] = useState<number>(1);
  const list = useMemo(() => new BehaviorSubject<PipelineList>({} as PipelineList), []);
  const create = useMemo(() => new BehaviorSubject<CreatePipelineResponse | null>(null), []);
  const get = useMemo(() => new BehaviorSubject<GetPipelineResponse>({} as GetPipelineResponse), []);
  const getMulti = useMemo(() => new BehaviorSubject<Array<GetPipelineResponse>>([]), []);
  const deleteContent = useMemo(() => new BehaviorSubject<string>(''), []);
  const logSourcePipelines = useMemo(() => new BehaviorSubject<Array<GetPipelineResponse>>([]), []);

  const multiContent = zip(getMulti, logSourcePipelines).pipe(
    map<Array<Array<GetPipelineResponse>>, Array<CustomPipeline>>((response) => {
      const logSourcePipelineIds = response[1].map((pipeline) => pipeline.id);
      const customPipelines = response[0].filter((pipeline) => !logSourcePipelineIds.includes(pipeline.id));

      return [...customPipelines, ...response[1]].map((pipeline) => {
        return { id: pipeline.id, name: pipeline.name, pipeline: load(pipeline.content) as ProcessingPipeline };
      });
    })
  );

  const conversionAPIClient = useMemo(() => {
    return new ConversionApi(DetectServiceConfig);
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(search);
    const conversionId = params.get(VAR_CONVERSION_ID);
    if (conversionId) {
      conversionAPIClient.listConversionCustomPipelines({ id: conversionId }).subscribe((e) => {
        getMulti.next(
          e.data.map((e) => ({
            id: e.id,
            content: e.content,
            updatedAt: new Date(e.updated_at),
            createdAt: new Date(e.created_at),
          }))
        );
      });
    }
  }, [conversionAPIClient, search, getMulti]);

  const listOperation = useCallback(
    (page: number) => {
      setLoading(true);
      setPage(page);
      let sub = timer(300)
        .pipe(
          switchMap(() => remote),
          switchMap((val) => {
            if (val) {
              return getPipelineList(page).pipe(map((e) => e.data));
            }

            return from(getPipelineListLocal(page));
          })
        )
        .subscribe({
          next(val) {
            list.next(val);
            setLoading(false);
            sub.unsubscribe();
          },
          error(err) {
            console.error(err);
            setLoading(false);
            sub.unsubscribe();
          },
        });
    },
    [list, remote]
  );

  const createOperation = useCallback(
    (request: CreatePipelineRequest) => {
      setLoading(true);
      let sub = remote
        .pipe(
          switchMap((val) => {
            if (val) {
              return createPipeline(request).pipe(map((e) => e.data));
            }

            return from(createPipelineLocal(request));
          }),
          // This is a tap, it takes the value of the output and applies a side_effect, allows us to do things outside of
          // the observable chain so that the value isn't affected. In this case, we take the response, and append it to
          // the list of pipelines.
          tap({
            next(val) {
              list.subscribe({
                next(_list) {
                  if (_list.data.filter((v) => v.id === val.id).length > 0) {
                    return;
                  }
                  list.next({
                    ..._list,
                    count: _list.count + 1,
                    data: [{ id: val.id, name: request.name ?? '' }, ..._list.data],
                  });
                },
              });
            },
          })
        )
        .subscribe({
          next(val) {
            create.next(val);
            setLoading(false);
            setTimeout(() => {
              create.next(null);
            }, 100);
            sub.unsubscribe();
          },
          error(err) {
            console.error(err);
            setLoading(false);
            sub.unsubscribe();
          },
        });
    },
    [create, list, remote]
  );

  const getOperation = useCallback(
    (id: string) => {
      setLoading(true);
      let sub = timer(300)
        .pipe(
          switchMap(() => remote),
          switchMap((val) => {
            if (val) {
              return getPipeline(id).pipe(map((e) => e.data));
            }
            return from(getPipelineLocal(id));
          })
        )
        .subscribe({
          next(val) {
            get.next(val);
            setLoading(false);
            sub.unsubscribe();
          },
          error(err) {
            console.error(err);
            setLoading(false);
            sub.unsubscribe();
          },
        });
    },
    [get, remote]
  );

  const getMultiOperation = useCallback(
    (ids: Array<string>) => {
      setLoading(true);
      let sub = timer(300)
        .pipe(
          switchMap(() =>
            ids.length === 0
              ? of([])
              : zip(
                  ids.map((id) =>
                    remote.getValue() ? getPipeline(id).pipe(map((e) => e.data)) : from(getPipelineLocal(id))
                  )
                )
          )
        )
        .subscribe({
          next(value) {
            getMulti.next(value);
            setLoading(false);
            sub.unsubscribe();
          },
          error(err) {
            console.error(err);
            setLoading(false);
            sub.unsubscribe();
          },
        });
    },
    [getMulti, remote]
  );

  const deleteOperation = useCallback(
    (id: string) => {
      setLoading(true);

      let sub = remote
        .pipe(
          switchMap((val) => {
            if (val) {
              return deletePipeline(id).pipe(map((e) => e.data));
            }
            return from(deletePipelineLocal(id));
          }),
          tap(() => {
            list.subscribe({
              next(listVal) {
                if (listVal.data.filter((v) => v.id === id).length === 0) {
                  return;
                }
                list.next({
                  ...listVal,
                  count: listVal.count - 1,
                  data: listVal.data.filter((item) => item.id !== id),
                });
              },
            });
          })
        )
        .subscribe({
          next() {
            get.next({} as GetPipelineResponse);
            deleteContent.next(id);
            setLoading(false);
            sub.unsubscribe();
          },
          error(err) {
            console.error(err);
            setLoading(false);
            sub.unsubscribe();
          },
        });
    },
    [remote, list, get, deleteContent]
  );

  const updateOperation = useCallback(
    (id: string, request: CreatePipelineRequest) => {
      setLoading(true);
      let updatePipelinePromise: Promise<void>;
      if (remote.getValue()) {
        updatePipelinePromise = updatePipeline(id, request);
      } else {
        updatePipelinePromise = updatePipelineLocal(id, request);
      }
      updatePipelinePromise
        .then(() => {
          const _list = list.getValue();
          // If we have a successful update, then we know that we need to update the pipeline in the list
          list.next({
            ..._list,
            data: _list?.data
              ? _list.data.map((item) => (item.id === id ? { id, name: request.name ?? '' } : item))
              : [{ id: id, name: request.name ?? '' }],
          });

          get.next({
            id: id,
            content: request.content,
            name: request.name,
            createdAt: new Date(),
            updatedAt: new Date(),
          });
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [get, remote, list]
  );

  const setLogsourcePipelinesOperation = useCallback(
    (ids: Array<string>) => {
      let sub = timer(300)
        .pipe(
          switchMap(() =>
            zip(
              ids.map((id) =>
                remote.getValue() ? getPipeline(id).pipe(map((e) => e.data)) : from(getPipelineLocal(id))
              )
            )
          )
        )
        .subscribe({
          next(value) {
            logSourcePipelines.next(value);
            sub.unsubscribe();
          },
          error(err) {
            console.error(err);
            sub.unsubscribe();
          },
        });
    },
    [remote, logSourcePipelines]
  );

  return (
    <PipelineContext.Provider
      value={{
        loading,
        remote,
        data: {
          page,
          list,
          create,
          get,
          getMulti,
          multiContent,
          delete: deleteContent,
          logSourcePipelines,
        },
        operations: {
          list: listOperation,
          create: createOperation,
          get: getOperation,
          getMulti: getMultiOperation,
          delete: deleteOperation,
          update: updateOperation,
          setLogSourcePipelines: setLogsourcePipelinesOperation,
          __setRemote: (val: boolean) => remote.next(val),
        },
      }}
    >
      {props.children}
    </PipelineContext.Provider>
  );
};

// Service calls

export const getPipelineList = (page: number): Observable<FetchResponse<PipelineList>> => {
  return getBackendSrv().fetch<PipelineList>({
    url: `/api/plugins/${PLUGIN_ID}/resources/service/v1/db/pipelines?page=${page}&itemsPerPage=1000`,
  });
};

export const createPipeline = (request: CreatePipelineRequest): Observable<FetchResponse<CreatePipelineResponse>> => {
  return getBackendSrv().fetch<CreatePipelineResponse>({
    url: `/api/plugins/${PLUGIN_ID}/resources/service/v1/db/pipelines`,
    method: 'POST',
    data: request,
  });
};

export const getPipeline = (id: string): Observable<FetchResponse<GetPipelineResponse>> => {
  return getBackendSrv().fetch<GetPipelineResponse>({
    url: `/api/plugins/${PLUGIN_ID}/resources/service/v1/db/pipelines/${id}`,
    method: 'GET',
  });
};

export const deletePipeline = (id: string): Observable<FetchResponse<void>> => {
  return getBackendSrv().fetch({
    url: `/api/plugins/${PLUGIN_ID}/resources/service/v1/db/pipelines/${id}`,
    method: 'DELETE',
  });
};

export const updatePipeline = (id: string, request: CreatePipelineRequest): Promise<void> => {
  return getBackendSrv().patch<void>(`/api/plugins/${PLUGIN_ID}/resources/service/v1/db/pipelines/${id}`, request);
};
// Local Storage calls

export const getPipelineListLocal = (page: number): Promise<PipelineList> => {
  let data: Array<PipelineListItem> = [];
  let ids = Object.keys(localStorage).filter((e) => e.startsWith('pipeline:'));

  for (let id of ids) {
    const cont: GetPipelineResponse = JSON.parse(localStorage.getItem(id)!);
    data.push({
      id: id,
      name: cont.name ?? id,
    });
  }
  return Promise.resolve<PipelineList>({
    page: page,
    pageSize: data.length,
    returned: data.length,
    count: data.length,
    data: data,
  });
};

export const createPipelineLocal = (request: CreatePipelineRequest): Promise<CreatePipelineResponse> => {
  let p: GetPipelineResponse = {
    id: uniqueId('pipeline:'),
    name: request.name,
    content: request.content,
    createdAt: new Date(),
    updatedAt: new Date(),
  };

  localStorage.setItem(p.id, JSON.stringify(p));

  return Promise.resolve<CreatePipelineResponse>({
    id: p.id,
  });
};

export const getPipelineLocal = (id: string): Promise<GetPipelineResponse> => {
  let p: GetPipelineResponse;
  try {
    p = JSON.parse(localStorage.getItem(id)!);
  } catch (e) {
    return Promise.reject(e);
  }

  if (!p) {
    return Promise.reject('Pipeline not found');
  }

  return Promise.resolve<GetPipelineResponse>({
    id: p.id,
    name: p.name,
    content: p.content,
    createdAt: p.createdAt,
    updatedAt: p.updatedAt,
  });
};

export const deletePipelineLocal = (id: string): Promise<void> => {
  localStorage.removeItem(id);
  return Promise.resolve();
};

export const updatePipelineLocal = (id: string, request: CreatePipelineRequest): Promise<void> => {
  return new Promise((resolve) => {
    let p = getPipelineLocal(id);
    p.then((response) => {
      response.updatedAt = new Date();
      response.name = request.name;
      response.content = request.content;
      localStorage.setItem(id, JSON.stringify(response));
      resolve();
    });
  });
};
