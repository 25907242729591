import { AppEvents, DataSourceApi } from '@grafana/data';
import { FetchResponse, config, getBackendSrv, getAppEvents } from '@grafana/runtime';
import { dump, loadAll } from 'js-yaml';
import React, { ReactNode, createContext, useCallback, useContext, useMemo } from 'react';
import { Observable, BehaviorSubject, map, take, switchMap, combineLatest, filter, of, tap } from 'rxjs';
import { NullID, PLUGIN_ID, PLUGIN_ROOT, PRODUCT_NAME } from 'shared/constants';
import { AlertGroup, AlertingConfig } from 'shared/alertingTypes';
import {
  AlertAssociationResponse,
  DashboardAssociationResponse,
  DashboardCreateResponse,
  ErrorResponse,
  SimplifiedAlertRuleRequest,
} from 'shared/requests/alerts';
import { Rules, SigmaRule } from 'shared/requests/conversions';
import { CascaderOption } from '@grafana/ui';
import { useTranslation } from 'react-i18next';

// As defined in the Sigma specification
// https://github.com/SigmaHQ/sigma-specification/blob/main/Sigma_specification.md#level
export enum SigmaRuleLevel {
  informational,
  low,
  medium,
  high,
  critical,
}

export type RuleSummary = {
  ruleIds: Array<string>;
  filenames: Array<string>;
  titles: Array<string>;
  descriptions: Array<string>;
  authors: Array<string>;
  urls: Array<string>;
  maxLevel: SigmaRuleLevel;
  categories: Array<string>;
  products: Array<string>;
  services: Array<string>;
  dates: Array<string>;
};

export interface AlertState {
  data: {
    queries: Observable<string>;
    parsedGroups: Observable<AlertGroup>;
    hasConflict: Observable<boolean>;
  };
  operations: {
    createAlert(
      queries: Observable<string>,
      datasource?: Observable<DataSourceApi | null>,
      conversionId?: Observable<string | null>,
      rules?: Observable<Rules | null>,
      group?: AlertGroup,
      lookback?: string,
      level?: string,
      tap?: (obs: FetchResponse<AlertAssociationResponse>) => void
    ): void;
    createDashboard(
      queries: Observable<string>,
      datasource?: Observable<DataSourceApi | null>,
      conversionId?: Observable<string | null>,
      rules?: Observable<Rules | null>,
      logLabels?: Array<string>,
      tap?: (obs: FetchResponse<DashboardAssociationResponse | ErrorResponse>) => void
    ): void;
    forceCreateAlert(datasource?: Observable<DataSourceApi | null>): void;
    abort(): void;
  };
  config: AlertingConfig;
}

export const AlertContext = createContext<AlertState>({ config: { namespace: PRODUCT_NAME } } as AlertState);

export const AlertProvider = (props: { children?: ReactNode; alertingConfig?: AlertingConfig }) => {
  const { t } = useTranslation();
  const queries = useMemo(() => new BehaviorSubject<string>(''), []);
  const parsedGroups = useMemo(
    () =>
      queries.pipe(
        map<string, AlertGroup>((value) => {
          // We know that pySigma-backend-loki will only ever return one set of groups
          const _rawParsedGroups = loadAll(value) as Array<{ groups: Array<AlertGroup> }>;
          if (_rawParsedGroups.length === 1) {
            return (
              // We also know from pySigma-backend-loki that there will only ever be one group
              _rawParsedGroups[0].groups[0] ?? {
                name: 'Unknown',
                rules: [],
              }
            );
          }
          return {
            name: 'Unknown',
            rules: [],
          };
        })
      ),
    [queries]
  );

  const hasConflict = useMemo(() => new BehaviorSubject<boolean>(false), []);

  const doForceCreate = useCallback(
    (datasourceUid: string) => {
      parsedGroups
        .pipe(
          take(1),
          switchMap((group) => upsertAlertGroup(datasourceUid, group))
        )
        .subscribe({
          next() {
            getAppEvents().publish({
              type: AppEvents.alertSuccess.name,
              payload: [t('errors.loki.createSuccess'), 'Successfully Created Alert'],
            });
            // Empty the state here, this prevents cyclical calls
            queries.next('');
            hasConflict.next(false);
          },
          error(err) {
            getAppEvents().publish({
              type: AppEvents.alertError.name,
              payload: [t('errors.loki.createError'), err.message],
            });
          },
        });
    },
    [parsedGroups, queries, hasConflict, t]
  );

  const opCreateAlert = useCallback(
    (
      _queries: Observable<string>,
      _datasource: Observable<DataSourceApi>,
      _conversionId: Observable<string>,
      _rules: Observable<Rules>,
      group?: AlertGroup,
      lookback?: string,
      level?: string,
      _tap?: (obs: FetchResponse<AlertAssociationResponse>) => void
    ) => {
      let namespace = props.alertingConfig?.folderUid;
      // FIXME: temporary workaround whilst 10.4 (with updated alerting API) is being rolled out
      const gversion = config.buildInfo.version.split('.').map((x) => Number.parseInt(x, 10));
      if (gversion[0] < 10 || (gversion[0] === 10 && gversion[1] <= 3)) {
        namespace = props.alertingConfig?.namespace ?? PRODUCT_NAME;
      }
      if (!namespace) {
        getAppEvents().publish({
          type: AppEvents.alertError.name,
          payload: [t('errors.loki.createError'), 'Folder UID not defined'],
        });
        return;
      }
      let interval = '1m';
      if (group && group.interval) {
        interval = group.interval;
      }
      let obs = combineLatest({
        queries: _queries,
        rules: _rules,
        datasource: _datasource,
        conversionId: _conversionId,
      }).pipe(
        take(1),
        switchMap((e) => {
          const summary = summariseRules(e.rules);
          let simplifiedBody: SimplifiedAlertRuleRequest = {
            // Max length of alert title is 190 characters
            title: summary.titles.join(' // ').substring(0, 190),
            query: e.queries,
            frequency: interval,
            description: summary.descriptions.join('\n'),
            annotations: {
              Author: summary.authors.join(' and '),
            },
            labels: {
              Level: level ?? SigmaRuleLevel[summary.maxLevel],
            },
          };
          if (lookback) {
            simplifiedBody.lookback = lookback;
          }
          if (simplifiedBody.labels === undefined) {
            simplifiedBody.labels = {};
          }
          if (simplifiedBody.annotations === undefined) {
            simplifiedBody.annotations = {};
          }
          if (summary.categories.length > 0) {
            simplifiedBody.labels.Categories = summary.categories.join(',');
          }
          if (summary.products.length > 0) {
            simplifiedBody.labels.Products = summary.products.join(',');
          }
          if (summary.services.length > 0) {
            simplifiedBody.labels.Services = summary.services.join(',');
          }
          if (summary.dates.length > 0) {
            simplifiedBody.annotations.Changed = summary.dates.join('\n');
          }
          if (summary.urls.length > 0) {
            if (summary.urls.length === 1) {
              simplifiedBody.annotations.Source = summary.urls[0];
            } else {
              summary.urls.forEach((url, i) => {
                // @ts-ignore: Apparently TS can't see the above block setting the annotations here?!
                simplifiedBody.annotations[`Source_${i}`] = url;
              });
            }
          }
          return getBackendSrv().fetch<AlertAssociationResponse>({
            url: `/api/plugins/${PLUGIN_ID}/resources/v1/alert.Associate?conversion_id=${encodeURIComponent(
              e.conversionId
            )}&format=simplified&namespace=${encodeURIComponent(namespace ?? '')}&groupname=${encodeURIComponent(
              group?.name ?? 'Every minute'
            )}&interval=${encodeURIComponent(interval)}`,
            method: 'POST',
            data: simplifiedBody,
            responseType: 'json',
          });
        })
      );

      if (_tap) {
        // Enable injection of another function to tap into the data from the observable
        // used to update other stores, or alerting.
        obs = obs.pipe(tap(_tap));
      }

      obs.subscribe({
        next: (e: FetchResponse<AlertAssociationResponse>) => {
          getAppEvents().publish({
            type: AppEvents.alertSuccess.name,
            payload: [t('errors.loki.createSuccess'), 'Successfully Created Alert'],
          });
        },
        error: (e: FetchResponse<AlertAssociationResponse>) => {
          console.error(e);
          getAppEvents().publish({
            type: AppEvents.alertError.name,
            payload: [t('errors.loki.createError'), e.data.message ?? 'unexpected error'],
          });
        },
      });
    },
    [props.alertingConfig?.folderUid, props.alertingConfig?.namespace, t]
  );

  const opCreateDashboard = useCallback(
    (
      _queries: Observable<string>,
      _datasource: Observable<DataSourceApi>,
      _conversionId: Observable<string>,
      _rules: Observable<Rules>,
      _logLabels?: Array<string>,
      _tap?: (obs: FetchResponse<DashboardAssociationResponse | ErrorResponse>) => void
    ) => {
      let folderUid = props.alertingConfig?.folderUid;
      if (!folderUid) {
        getAppEvents().publish({
          type: AppEvents.alertError.name,
          payload: ['Cannot create dashboard', 'Folder UID not defined'],
        });
        return;
      }
      let obs = combineLatest({
        queries: _queries,
        rules: _rules,
        datasource: _datasource,
        conversionId: _conversionId,
      }).pipe(
        take(1),
        switchMap((e) => {
          const summary = summariseRules(e.rules);
          return getBackendSrv().fetch<DashboardAssociationResponse | ErrorResponse>({
            url: `/api/plugins/${PLUGIN_ID}/resources/v1/dashboard.Associate`,
            method: 'POST',
            data: {
              conversionId: e.conversionId,
              title: summary.titles.join(' // '),
              description: summary.descriptions.join('\n'),
              queries: e.queries,
              datasource: {
                type: e.datasource.type,
                uid: e.datasource.uid,
              },
              folderUid: props.alertingConfig?.folderUid ?? '',
              logLabels: _logLabels ?? [],
            },
          });
        })
      );

      if (_tap) {
        obs = obs.pipe(tap(_tap));
      }

      obs.subscribe({
        next: (e: FetchResponse<DashboardAssociationResponse | DashboardCreateResponse | ErrorResponse>) => {
          if ('uid' in e.data && 'message' in e.data) {
            getAppEvents().publish({
              type: AppEvents.alertSuccess.name,
              payload: [t('errors.dashboard.createSuccess'), e.data.url],
            });
          } else if ('uid' in e.data) {
            // TODO: update message to reflect partial success? (Not successfully persisted in the service database)
            getAppEvents().publish({
              type: AppEvents.alertSuccess.name,
              payload: [t('errors.dashboard.createSuccess'), e.data.url],
            });
          } else {
            console.error(e);
            getAppEvents().publish({
              type: AppEvents.alertError.name,
              payload: [t('errors.dashboard.createError'), e.data.message ?? 'unexpected error'],
            });
          }
        },
        error: (e: FetchResponse<DashboardCreateResponse | ErrorResponse>) => {
          console.error(e);
          // Assuming only error responses will come back for errors?
          if ('message' in e.data) {
            getAppEvents().publish({
              type: AppEvents.alertError.name,
              payload: [t('errors.dashboard.createError'), e.data.message ?? 'unexpected error'],
            });
          } else {
            getAppEvents().publish({
              type: AppEvents.alertError.name,
              payload: [t('errors.dashboard.createError'), 'unexpected error'],
            });
          }
        },
      });
    },
    [props.alertingConfig?.folderUid, t]
  );

  const opForceCreateAlert = useCallback(
    (_datasource: Observable<DataSourceApi>) => {
      _datasource.pipe(take(1)).subscribe((value) => {
        doForceCreate(value.uid);
      });
    },
    [doForceCreate]
  );

  const opAbort = useCallback(() => {
    queries.next('');
    hasConflict.next(false);
  }, [queries, hasConflict]);

  return (
    <AlertContext.Provider
      value={{
        data: {
          queries,
          parsedGroups,
          hasConflict,
        },
        operations: {
          createAlert: opCreateAlert,
          createDashboard: opCreateDashboard,
          forceCreateAlert: opForceCreateAlert,
          abort: opAbort,
        },
        config: props.alertingConfig ?? { namespace: PRODUCT_NAME },
      }}
    >
      {props.children}
    </AlertContext.Provider>
  );
};

const getUrl = (datasourceUid: string) => {
  return `/api/datasources/proxy/uid/${datasourceUid}/loki/api/v1/rules/${encodeURIComponent(PLUGIN_ID)}`;
};

const checkAlertGroup = (datasourceUid: string, group: AlertGroup): Observable<boolean> => {
  return getBackendSrv()
    .fetch<void>({
      url: `${getUrl(datasourceUid)}/${group.name}`,
      method: 'GET',
    })
    .pipe(
      map((response) => {
        return response.status === 200;
      })
    );
};

const upsertAlertGroup = (datasourceUid: string, group: AlertGroup): Observable<boolean> => {
  return getBackendSrv()
    .fetch<{ status: string; error: string }>({
      url: getUrl(datasourceUid),
      method: 'POST',
      data: dump(group),
      headers: {
        'Content-Type': 'application/yaml',
      },
    })
    .pipe(
      map((response) => response.data),
      map((value) => {
        if (value.error !== '') {
          throw new Error(value.error);
        }
        return true;
      })
    );
};

export const summariseRules = (rules: Rules): RuleSummary => {
  let ruleIds: Set<string> = new Set(),
    filenames: Set<string> = new Set(),
    titles: Set<string> = new Set(),
    descriptions: Set<string> = new Set(),
    authors: Set<string> = new Set(),
    urls: Set<string> = new Set(),
    levels: Set<SigmaRuleLevel> = new Set(),
    categories: Set<string> = new Set(),
    products: Set<string> = new Set(),
    services: Set<string> = new Set(),
    lastChanged: Set<string> = new Set();
  // TODO: this feels rather inefficient - should probably be memo-ised if it needs to be done repeatedly?
  Object.entries(rules).forEach((e) => {
    const content = loadAll(e[1].content) as Array<SigmaRule>;
    const splitAt = e[0].indexOf('/');
    const repo = e[0].substring(0, splitAt),
      filename = e[0].substring(splitAt + 1);
    content.forEach((r) => {
      ruleIds.add(r.id ?? '');
      titles.add(r.title ?? '');
      filenames.add(filename ?? '');
      descriptions.add(r.description ?? '');
      authors.add(r.author ?? '');
      lastChanged.add(r.modified ?? r.date ?? '');
      if (r.level !== undefined && SigmaRuleLevel[r.level]) {
        levels.add(SigmaRuleLevel[r.level]);
      }
      if (r.logsource) {
        categories.add(typeof r.logsource['category'] === 'string' ? r.logsource['category'] : '');
        products.add(typeof r.logsource['product'] === 'string' ? r.logsource['product'] : '');
        services.add(typeof r.logsource['service'] === 'string' ? r.logsource['service'] : '');
      }
    });
    if (e[1].origin === 'custom' || e[1].origin === 'database' || repo === NullID) {
      urls.add(
        new URL(
          (config.appSubUrl ?? '') +
            PLUGIN_ROOT +
            `/sigma/ruleEditor?filename=` +
            e[0].substring(e[0].indexOf('/') + 1),
          !!!config.appUrl ? 'http://localhost:3000/' : config.appUrl
        ).toString()
      );
    } else {
      let ref = e[1].ref ?? 'master',
        path = e[0].substring(e[0].indexOf('/'));
      if (path.indexOf('#') !== -1) {
        ref = e[0].substring(e[0].indexOf('#') + 1);
        path = path.substring(0, path.indexOf('#'));
      }
      urls.add(`https://github.com/${e[1].owner ?? 'SigmaHQ'}/${e[1].repo ?? 'sigma'}/blob/${ref + path}`);
    }
  });

  return {
    ruleIds: [...ruleIds].filter((e) => e.length > 0),
    filenames: [...filenames].filter((e) => e.length > 0),
    titles: [...titles].filter((e) => e.length > 0),
    descriptions: [...descriptions].filter((e) => e.length > 0),
    authors: [...authors].filter((e) => e.length > 0),
    urls: [...urls].filter((e) => e.length > 0),
    maxLevel: [...levels].reduce((acc, val) => (val > acc ? val : acc), SigmaRuleLevel.informational),
    categories: [...categories].filter((e) => e.length > 0),
    products: [...products].filter((e) => e.length > 0),
    services: [...services].filter((e) => e.length > 0),
    dates: [...lastChanged].filter((e) => e.length > 0),
  };
};
