// Define constants for plugin wide consumption here to make copy easier to change.
const PLUGIN_NAME_EN = 'Grafana Detect';

// Then define translations, at the moment only english is supported (James C doesn't speak any other language well enough to translate here)
export const enTranslation = {
  installPage: {
    title: `Install ${PLUGIN_NAME_EN}`,
    introParagraph: `Welcome to ${PLUGIN_NAME_EN}, Grafana's Security Operations product. This page will help you or your administrator to setup the plugin to start exploring your data in a security centric way.`,
    requireAdminParagraph: `It appears that ${PLUGIN_NAME_EN} has not been configured for this Grafana instance yet, hang tight until an administrator completes the setup.`,
    adminIntro: `It appears that ${PLUGIN_NAME_EN} has not been configured for this Grafana instance yet, as an administrator of this instance you can use this page to finalise the installation.`,
    configureSigmaAPI: `Configure Sigma API`,
    configureService: `Configure ${PLUGIN_NAME_EN} Service`,
    configureGitHub: `Configure GitHub Integration`,
    submit: 'Initialize',
    modalTitle: `Initializing ${PLUGIN_NAME_EN}`,
    loadingTexts: [
      'Brewing Coffee',
      'Compiling Dossiers',
      'Generating Threat Intelligence',
      'Downloading more RAM',
      'Observing Quantum State',
      'Checking for backdoors',
      'Playing Uno Reverse Card on Hackers',
      'Preparing for the phishing season',
    ],
    sigmaAPIConfig: {
      title: `Configure Sigma API`,
      addressLabel: 'Sigma API HTTP Address',
      addressDescription: 'Use the format host:port',
    },
    serviceConfig: {
      title: `Configure ${PLUGIN_NAME_EN} Service`,
      addressLabel: `${PLUGIN_NAME_EN} Service Address`,
      accessTokenLabel: 'Service Access Token',
    },
    githubConfig: {
      title: `Configure GitHub Integration`,
    },
    dashboardConfig: {
      title: `Configure Dashboards`,
      incidentDSLabel: `Grafana Incident Data Source`,
      incidentDSDetail: `If applicable, select the Grafana Incident data source for security incidents`,
      alertingHistoryDSLabel: `Grafana Alert State History Data Source`,
      alertingHistoryDetail: `If applicable, select the Loki index where Alert State History is being stored`,
    },
  },
  converter: {
    alerting: {
      'see-alert': 'View Associated Alert',
      update: 'Update Associated Alert',
      minute: 'Every minute',
      fiveMinute: 'Every 5 minutes',
      hour: 'Every hour',
      sixHours: 'Every six hours',
      day: 'Every day',
      button: 'Alert',
      createButton: 'Create Alert',
      alertConf: 'Configure Alert',
      frequency: 'Alert Frequency',
      frequencyDetail: 'How frequently the alert should be evaluated',
      lookback: 'Lookback',
      lookbackDetail: 'Add an optional delay to the query time window to account for data ingest',
      level: 'Override alert level',
      levelDetail: 'Change the alert level from the highest in the rule(s) to a custom level',
      informational: 'Informational',
      informationalDetail:
        'Rule is intended for enrichment of events, e.g. by tagging them. No case or alerting should be triggered by such rules because it is expected that a huge amount of events will match these rules.',
      low: 'Low',
      lowDetail:
        "Notable event but rarely an incident. Low rated events can be relevant in high numbers or combination with others. Immediate reaction shouldn't be necessary, but a regular review is recommended.",
      medium: 'Medium',
      mediumDetail: 'Relevant event that should be reviewed manually on a more frequent basis.',
      high: 'High',
      highDetail: 'Relevant event that should trigger an internal alert and requires a prompt review.',
      critical: 'Critical',
      criticalDetail:
        'Highly relevant event that indicates an incident. Critical events should be reviewed immediately. It is used only for cases in which probability borders certainty.',
    },
    dashboards: {
      createButton: 'Create Dashboard',
      button: 'Dashboard',
      update: 'Update Dashboard',
      'see-dashboard': 'View Associated Dashboard',
    },
  },
  errors: {
    sigma: {
      invalidYaml: 'Invalid Sigma Pipeline',
      noQueries: 'No queries were generated from the conversion',
      errorQuery: 'Error in query',
    },
    loki: {
      createError: 'Alert Rule Error',
      createSuccess: 'Alert Rule Created',
      noRules: 'No Rules Provided',
    },
    service: {
      createSigmaRuleError: 'Save Sigma Rule Failed',
      createSigmaRuleSuccess: 'Sigma Rule Saved',
    },
    datasource: {
      queryError: 'Data Source Query Error',
    },
    gma: {
      createSuccess: 'Alert Saved',
      createError: 'Alert Create Failed',
    },
    dashboard: {
      createSuccess: 'Dashboard Created',
      createError: 'Dashboard Create Failed',
    },
    folder: {
      undef: 'Folder Not Configured',
    },
    repository: {
      missingFields: 'Custom Repository Missing Fields',
      customFetchError: 'Repository Fetch Error',
      customSaved: 'Custom Repository Saved',
      customDeleted: 'Custom Repository Deleted',
      customRepoSaveError: 'Custom Repository Save Error',
      customRepoDeleteError: 'Custom Repository Delete Error',
      mainFetchError: 'Main Repository Fetch Error',
      forceFetchError: 'Force Fetch Repository Error',
      forceFetchSuccess: 'Force Fetch Repository Success',
      customRetrieve: 'Cannot Retrieve Custom Repository',
      mainRetrieve: 'Cannot Retrieve Main Repository',
      mainRetrieveUuid: 'Cannot Retrieve Main Repository UUID',
    },
    unknown: 'Unknown Error Occurred',
  },
  buttons: {
    cancel: 'Cancel',
    confirm: 'Confirm',
  },
};

export const enGBTranslation = {
  ...enTranslation,
  installPage: {
    ...enTranslation.installPage,
    submit: 'Initialise',
    modalTitle: `Initialising ${PLUGIN_NAME_EN}`,
  },
};
