import {
  CustomVariable,
  SceneComponentProps,
  sceneGraph,
  SceneObjectBase,
  SceneObjectState,
  VariableDependencyConfig,
} from '@grafana/scenes';
import { Button, Modal, Pagination, PanelChrome, useStyles2 } from '@grafana/ui';
import { AppEvents, DataFrame, GrafanaTheme2, LoadingState } from '@grafana/data';
import { css } from '@emotion/css';
import { Table, TableHeader } from 'components/scenes/logsource/table';
import { DisplayRow } from 'components/scenes/logsource/displayRow';
import { DetectEvent, DetectEventTypes } from 'scenes/events';
import { getAppEvents, RefreshEvent } from '@grafana/runtime';
import { Configuration as DataSourceConfigApiCfg, DataSourceConfigApi } from 'api/detect-service';
import { PLUGIN_ID } from 'shared/constants';
import React, { useState } from 'react';
import { t } from 'i18next';

export interface LogSourceRow {
  id: string;
  name: string;
  source: string;
  config: any;
  uid: string;
  description: string;
}

export interface LogSourceOverviewSceneProps extends SceneObjectState {
  logsource: Array<string>;
}

export class LogSourceOverviewScene extends SceneObjectBase<LogSourceOverviewSceneProps> {
  protected static Component = Renderer;
  protected datasourceConfigApi = new DataSourceConfigApi(
    new DataSourceConfigApiCfg({
      basePath: `/api/plugins/${PLUGIN_ID}/resources/service/v1/db`,
    })
  );

  protected _variableDependency = new VariableDependencyConfig(this, {
    variableNames: ['logsource'],
    onReferencedVariableValueChanged: (variable) => {
      this.setState({ [variable.state.name]: variable.getValue()?.toString() });
    },
  });

  constructor(props: Partial<LogSourceOverviewSceneProps>) {
    super({
      logsource: [''],

      ...props,
    });

    this.addActivationHandler(() => {
      this.setState({ logsource: sceneGraph.lookupVariable('logsource', this)?.getValue() as Array<string> });

      this.getRoot().subscribeToEvent(DetectEvent, (evt) => {
        switch (evt.payload.type) {
          case DetectEventTypes.LogSourceConfigDelete:
            this.doDeleteLogSource(evt.payload.payload.id, evt.payload.payload.name);
            break;
        }
      });
    });
  }

  public deleteLogSource(id: string, name: string) {
    this.publishEvent(
      new DetectEvent({
        type: DetectEventTypes.LogSourceConfigDelete,
        payload: { id: id, name: name },
      }),
      true
    );
  }

  doDeleteLogSource(id: string, name: string) {
    const appEvents = getAppEvents();
    this.datasourceConfigApi.deleteDataSourceConfig({ id: id }).subscribe({
      next: (_) => {
        appEvents.publish({
          type: AppEvents.alertSuccess.name,
          payload: ['Deleted Log Source config', name],
        });
        sceneGraph.getTimeRange(this).onRefresh();
      },
      error: (error) => {
        let errorMsg = error?.response?.status;
        if (errorMsg === undefined) {
          errorMsg = 'Unknown error';
        }
        errorMsg = errorMsg[0].toUpperCase() + errorMsg.slice(1);
        console.error('Cannot delete datasource config', id, name, error);
        appEvents.publish({
          type: AppEvents.alertError.name,
          payload: ['Cannot delete log source config "' + name + '"', errorMsg],
        });
      },
    });
  }

  onSelect(val: string) {
    const logsourceVar = sceneGraph.lookupVariable('logsource', this) as CustomVariable;
    const currentVal = logsourceVar.getValue() as Array<string>;
    if (currentVal.includes(val)) {
      logsourceVar.changeValueTo(currentVal.filter((v) => v !== val));
      return;
    }
    logsourceVar.changeValueTo(Array.from(new Set([...(logsourceVar.getValue() as Array<string>), val])));
  }

  onClear() {
    const logsourceVar = sceneGraph.lookupVariable('logsource', this) as CustomVariable;
    logsourceVar.changeValueTo([]);
  }

  getRows(series?: DataFrame) {
    let rows: Array<LogSourceRow> = [];
    if (!series) {
      return rows;
    }
    for (let index = 0; index < series.length; index++) {
      const name = series.fields.find((field) => field.name === 'name')?.values[index];
      const id = series.fields.find((field) => field.name === 'id')?.values[index];
      const uid = series.fields.find((field) => field.name === 'uid')?.values[index];
      const config = series.fields.find((field) => field.name === 'config')?.values[index];
      const description = series.fields.find((field) => field.name === 'description')?.values[index];
      rows.push({
        id: id,
        name: name?.toString() ?? 'Not Found',
        source: 'Source 1',
        config: config,
        uid: uid,
        description: description,
      });
    }
    return rows;
  }
}

function Renderer({ model }: SceneComponentProps<LogSourceOverviewScene>) {
  const { logsource } = model.useState();
  const styles = useStyles2(getStyles);
  const [page, setPage] = useState(1);
  const [displayDeleteDialog, setDisplayDeleteDialog] = useState<{ [id: string]: Boolean }>({});

  const dataState = sceneGraph.getData(model).useState();
  const rows = model.getRows(dataState.data?.series[0]);

  return (
    <div className={styles.container}>
      <PanelChrome title="Log Source Configs" loadingState={dataState.data?.state ?? LoadingState.NotStarted}>
        {dataState.data?.state !== LoadingState.Done ? null : (
          <>
            <Table key="table">
              <TableHeader
                key="header"
                onSelect={() => {
                  model.onClear();
                }}
              />
              {rows.slice((page - 1) * 25, (page - 1) * 25 + 25).map((e) => (
                <>
                  <DisplayRow
                    id={e.id}
                    name={e.name}
                    key={e.id}
                    uid={e.uid}
                    config={e.config}
                    logsource={logsource}
                    onDelete={() => {
                      setDisplayDeleteDialog({ ...displayDeleteDialog, [e.id]: true });
                    }}
                    description={e.description}
                    onSelect={() => {
                      model.onSelect(e.id);
                    }}
                  />
                  <Modal title="Log Source Config Deletion" isOpen={!!displayDeleteDialog[e.id]}>
                    <div>
                      Are you sure you want to delete the Log Source Config &quot;{e.name}&quot;? Attached conversions
                      will be discarded.
                    </div>
                    <Modal.ButtonRow>
                      <Button
                        variant="secondary"
                        fill="outline"
                        onClick={() => {
                          setDisplayDeleteDialog({ ...displayDeleteDialog, [e.id]: false });
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="destructive"
                        onClick={() => {
                          setDisplayDeleteDialog({ ...displayDeleteDialog, [e.id]: false });
                          model.deleteLogSource(e.id, e.name);
                        }}
                      >
                        Confirm
                      </Button>
                    </Modal.ButtonRow>
                  </Modal>
                </>
              ))}
            </Table>
            <div key="spacer" style={{ padding: 20 }} />
            <Pagination
              key="paginator"
              currentPage={page}
              numberOfPages={Math.ceil(rows.length / 25)}
              onNavigate={(page) => {
                setPage(page);
              }}
            />
          </>
        )}
      </PanelChrome>
    </div>
  );
}

const getStyles = (theme: GrafanaTheme2) => ({
  container: css({
    flexGrow: 1,
  }),
  headerCheckbox: css({
    padding: theme.spacing.x0_25,
    margin: theme.spacing.x0_25,
  }),
});
