// tslint:disable
/**
 * Detect service API.
 * The Detect service exposes an HTTP API to do actions within the plugin like saving pipelines, conversions of rules, and manage external rule sources.
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: security-operations@grafana.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Observable } from 'rxjs';
import type { AjaxResponse } from 'rxjs/ajax';
import { BaseAPI, throwIfNullOrUndefined, encodeURI } from '../runtime';
import type { OperationOpts, HttpHeaders, HttpQuery } from '../runtime';
import type {
  CreateDataSourceConfigRequestModel,
  DataSourceConfigCreateModel,
  DataSourceConfigReadModel,
  DataSourceConfigUpdateModel,
  ListConversion400ResponseModel,
  ListDataSourceConfig200ResponseModel,
  ListDataSourceConfigConversions200ResponseModel,
  ListDataSourceConfigPipelines200ResponseModel,
  UpdateDataSourceConfigRequestModel,
} from '../models';

export interface CreateDataSourceConfigRequest {
  createDataSourceConfigRequestModel: CreateDataSourceConfigRequestModel;
}

export interface DeleteDataSourceConfigRequest {
  id: string;
}

export interface ListDataSourceConfigRequest {
  page?: number;
  itemsPerPage?: number;
  startTime?: string;
  endTime?: string;
}

export interface ListDataSourceConfigConversionsRequest {
  id: string;
  page?: number;
  itemsPerPage?: number;
  startTime?: string;
  endTime?: string;
}

export interface ListDataSourceConfigPipelinesRequest {
  id: string;
  page?: number;
  itemsPerPage?: number;
  startTime?: string;
  endTime?: string;
}

export interface ReadDataSourceConfigRequest {
  id: string;
}

export interface UpdateDataSourceConfigRequest {
  id: string;
  updateDataSourceConfigRequestModel: UpdateDataSourceConfigRequestModel;
}

/**
 * no description
 */
export class DataSourceConfigApi extends BaseAPI {
  /**
   * Creates a new DataSourceConfig and persists it to storage.
   * Create a new DataSourceConfig
   */
  createDataSourceConfig({
    createDataSourceConfigRequestModel,
  }: CreateDataSourceConfigRequest): Observable<DataSourceConfigCreateModel>;
  createDataSourceConfig(
    { createDataSourceConfigRequestModel }: CreateDataSourceConfigRequest,
    opts?: OperationOpts
  ): Observable<AjaxResponse<DataSourceConfigCreateModel>>;
  createDataSourceConfig(
    { createDataSourceConfigRequestModel }: CreateDataSourceConfigRequest,
    opts?: OperationOpts
  ): Observable<DataSourceConfigCreateModel | AjaxResponse<DataSourceConfigCreateModel>> {
    throwIfNullOrUndefined(
      createDataSourceConfigRequestModel,
      'createDataSourceConfigRequestModel',
      'createDataSourceConfig'
    );

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
    };

    return this.request<DataSourceConfigCreateModel>(
      {
        url: '/data-source-configs',
        method: 'POST',
        headers,
        body: createDataSourceConfigRequestModel,
      },
      opts?.responseOpts
    );
  }

  /**
   * Deletes the DataSourceConfig with the requested ID.
   * Deletes a DataSourceConfig by ID
   */
  deleteDataSourceConfig({ id }: DeleteDataSourceConfigRequest): Observable<void>;
  deleteDataSourceConfig(
    { id }: DeleteDataSourceConfigRequest,
    opts?: OperationOpts
  ): Observable<void | AjaxResponse<void>>;
  deleteDataSourceConfig(
    { id }: DeleteDataSourceConfigRequest,
    opts?: OperationOpts
  ): Observable<void | AjaxResponse<void>> {
    throwIfNullOrUndefined(id, 'id', 'deleteDataSourceConfig');

    return this.request<void>(
      {
        url: '/data-source-configs/{id}'.replace('{id}', encodeURI(id)),
        method: 'DELETE',
      },
      opts?.responseOpts
    );
  }

  /**
   * List DataSourceConfigs.
   * List DataSourceConfigs
   */
  listDataSourceConfig({
    page,
    itemsPerPage,
    startTime,
    endTime,
  }: ListDataSourceConfigRequest): Observable<ListDataSourceConfig200ResponseModel>;
  listDataSourceConfig(
    { page, itemsPerPage, startTime, endTime }: ListDataSourceConfigRequest,
    opts?: OperationOpts
  ): Observable<AjaxResponse<ListDataSourceConfig200ResponseModel>>;
  listDataSourceConfig(
    { page, itemsPerPage, startTime, endTime }: ListDataSourceConfigRequest,
    opts?: OperationOpts
  ): Observable<ListDataSourceConfig200ResponseModel | AjaxResponse<ListDataSourceConfig200ResponseModel>> {
    const query: HttpQuery = {};

    if (page != null) {
      query['page'] = page;
    }
    if (itemsPerPage != null) {
      query['itemsPerPage'] = itemsPerPage;
    }
    if (startTime != null) {
      query['startTime'] = (startTime as any).toISOString();
    }
    if (endTime != null) {
      query['endTime'] = (endTime as any).toISOString();
    }

    return this.request<ListDataSourceConfig200ResponseModel>(
      {
        url: '/data-source-configs',
        method: 'GET',
        query,
      },
      opts?.responseOpts
    );
  }

  /**
   * List attached Conversions.
   * List attached Conversions
   */
  listDataSourceConfigConversions({
    id,
    page,
    itemsPerPage,
    startTime,
    endTime,
  }: ListDataSourceConfigConversionsRequest): Observable<ListDataSourceConfigConversions200ResponseModel>;
  listDataSourceConfigConversions(
    { id, page, itemsPerPage, startTime, endTime }: ListDataSourceConfigConversionsRequest,
    opts?: OperationOpts
  ): Observable<AjaxResponse<ListDataSourceConfigConversions200ResponseModel>>;
  listDataSourceConfigConversions(
    { id, page, itemsPerPage, startTime, endTime }: ListDataSourceConfigConversionsRequest,
    opts?: OperationOpts
  ): Observable<
    ListDataSourceConfigConversions200ResponseModel | AjaxResponse<ListDataSourceConfigConversions200ResponseModel>
  > {
    throwIfNullOrUndefined(id, 'id', 'listDataSourceConfigConversions');

    const query: HttpQuery = {};

    if (page != null) {
      query['page'] = page;
    }
    if (itemsPerPage != null) {
      query['itemsPerPage'] = itemsPerPage;
    }
    if (startTime != null) {
      query['startTime'] = (startTime as any).toISOString();
    }
    if (endTime != null) {
      query['endTime'] = (endTime as any).toISOString();
    }

    return this.request<ListDataSourceConfigConversions200ResponseModel>(
      {
        url: '/data-source-configs/{id}/conversions'.replace('{id}', encodeURI(id)),
        method: 'GET',
        query,
      },
      opts?.responseOpts
    );
  }

  /**
   * List attached Pipelines.
   * List attached Pipelines
   */
  listDataSourceConfigPipelines({
    id,
    page,
    itemsPerPage,
    startTime,
    endTime,
  }: ListDataSourceConfigPipelinesRequest): Observable<ListDataSourceConfigPipelines200ResponseModel>;
  listDataSourceConfigPipelines(
    { id, page, itemsPerPage, startTime, endTime }: ListDataSourceConfigPipelinesRequest,
    opts?: OperationOpts
  ): Observable<AjaxResponse<ListDataSourceConfigPipelines200ResponseModel>>;
  listDataSourceConfigPipelines(
    { id, page, itemsPerPage, startTime, endTime }: ListDataSourceConfigPipelinesRequest,
    opts?: OperationOpts
  ): Observable<
    ListDataSourceConfigPipelines200ResponseModel | AjaxResponse<ListDataSourceConfigPipelines200ResponseModel>
  > {
    throwIfNullOrUndefined(id, 'id', 'listDataSourceConfigPipelines');

    const query: HttpQuery = {};

    if (page != null) {
      query['page'] = page;
    }
    if (itemsPerPage != null) {
      query['itemsPerPage'] = itemsPerPage;
    }
    if (startTime != null) {
      query['startTime'] = (startTime as any).toISOString();
    }
    if (endTime != null) {
      query['endTime'] = (endTime as any).toISOString();
    }

    return this.request<ListDataSourceConfigPipelines200ResponseModel>(
      {
        url: '/data-source-configs/{id}/pipelines'.replace('{id}', encodeURI(id)),
        method: 'GET',
        query,
      },
      opts?.responseOpts
    );
  }

  /**
   * Finds the DataSourceConfig with the requested ID and returns it.
   * Find a DataSourceConfig by ID
   */
  readDataSourceConfig({ id }: ReadDataSourceConfigRequest): Observable<DataSourceConfigReadModel>;
  readDataSourceConfig(
    { id }: ReadDataSourceConfigRequest,
    opts?: OperationOpts
  ): Observable<AjaxResponse<DataSourceConfigReadModel>>;
  readDataSourceConfig(
    { id }: ReadDataSourceConfigRequest,
    opts?: OperationOpts
  ): Observable<DataSourceConfigReadModel | AjaxResponse<DataSourceConfigReadModel>> {
    throwIfNullOrUndefined(id, 'id', 'readDataSourceConfig');

    return this.request<DataSourceConfigReadModel>(
      {
        url: '/data-source-configs/{id}'.replace('{id}', encodeURI(id)),
        method: 'GET',
      },
      opts?.responseOpts
    );
  }

  /**
   * Updates a DataSourceConfig and persists changes to storage.
   * Updates a DataSourceConfig
   */
  updateDataSourceConfig({
    id,
    updateDataSourceConfigRequestModel,
  }: UpdateDataSourceConfigRequest): Observable<DataSourceConfigUpdateModel>;
  updateDataSourceConfig(
    { id, updateDataSourceConfigRequestModel }: UpdateDataSourceConfigRequest,
    opts?: OperationOpts
  ): Observable<AjaxResponse<DataSourceConfigUpdateModel>>;
  updateDataSourceConfig(
    { id, updateDataSourceConfigRequestModel }: UpdateDataSourceConfigRequest,
    opts?: OperationOpts
  ): Observable<DataSourceConfigUpdateModel | AjaxResponse<DataSourceConfigUpdateModel>> {
    throwIfNullOrUndefined(id, 'id', 'updateDataSourceConfig');
    throwIfNullOrUndefined(
      updateDataSourceConfigRequestModel,
      'updateDataSourceConfigRequestModel',
      'updateDataSourceConfig'
    );

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
    };

    return this.request<DataSourceConfigUpdateModel>(
      {
        url: '/data-source-configs/{id}'.replace('{id}', encodeURI(id)),
        method: 'PATCH',
        headers,
        body: updateDataSourceConfigRequestModel,
      },
      opts?.responseOpts
    );
  }
}
