import React, { useContext, useEffect, useState } from 'react';
import { Button, ButtonCascader } from '@grafana/ui';
import { ConversionContext } from 'stores/conversion';
import { combineLatest } from 'rxjs';
import { useTranslation } from 'react-i18next';

interface AlertCreateButtonProps {
  disabled: boolean;
  alertID: string;

  onCreateAlert(event: any): void;
}

interface StatefulAlertCreateButtonProps {
  namespace: string;

  onCreateAlert(event: any): void;
}

export const AlertCreateButton = (props: AlertCreateButtonProps) => {
  const { t } = useTranslation();

  if (props.alertID) {
    const returnPath = encodeURIComponent(window.location.pathname + window.location.search);
    return (
      <ButtonCascader
        options={[
          {
            label: t('converter.alerting.see-alert'),
            value: 'see-alert',
          },
          {
            label: t('converter.alerting.update'),
            value: 'update-alert',
          },
        ]}
        icon="bell"
        variant="secondary"
        disabled={props.disabled}
        onChange={(groupnames, options) => {
          switch (options[0].value) {
            case 'see-alert':
              window.open(`/alerting/grafana/${props.alertID}/view?returnTo=${returnPath}`);
              break;
            case 'update-alert':
              props.onCreateAlert({});
              break;
          }
        }}
      >
        {t('converter.alerting.button')}
      </ButtonCascader>
    );
  }
  return (
    <Button disabled={props.disabled} icon="bell" variant="secondary" onClick={props.onCreateAlert}>
      {t('converter.alerting.createButton')}
    </Button>
  );
};

export const StatefulAlertCreateButton = (props: StatefulAlertCreateButtonProps) => {
  const [disabled, setDisabled] = useState<boolean>(true);
  const [alertID, setAlertID] = useState<string>('');

  const {
    data: { queries: stateQueries, datasource: stateDatasource, alertID: stateAlertID },
  } = useContext(ConversionContext);

  // Effect for state subscription
  useEffect(() => {
    // Combine latest ensures that all observables provide a value (through next) before emitting
    combineLatest({
      stateQueries,
      stateDatasource,
    }).subscribe((e) => {
      setDisabled(!e.stateQueries || !e.stateDatasource);
    });
    stateAlertID.subscribe(setAlertID);
  }, [stateQueries, stateDatasource, stateAlertID]);

  return <AlertCreateButton {...props} disabled={disabled} alertID={alertID} />;
};
