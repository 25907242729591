import { Checkbox, IconButton, Switch, useStyles2 } from '@grafana/ui';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TableRow } from './table';
import { LogSourceDataSource } from './datasourceDisplay';
import { DataQueryRequest, DataSourceApi, GrafanaTheme2 } from '@grafana/data';
import { css } from '@emotion/css';
import { getExploreUrl } from 'utils/explore';
import { DataQuery } from '@grafana/schema';
import { LokiQuery } from 'shared/lokiTypes';
import { RowButtons } from './rowButtons';
import { getBackendSrv } from 'components/__mocks__/@grafana/runtime';
import { getDataSourceSrv } from '@grafana/runtime';
import { render } from './queries/loki';

interface DisplayRowProps {
  id: string;
  name: string;
  logsource: Array<string>;
  onDelete: () => void;
  uid: string;
  config: any;
  description: string;
  onSelect: () => void;
}

export const DisplayRow = (props: DisplayRowProps) => {
  const styles = useStyles2(getStyles);
  const [open, setOpen] = useState(false);
  const [datasource, setDatasource] = useState<DataSourceApi | null>(null);

  useEffect(() => {
    getDataSourceSrv()
      .get(props.uid)
      .then((ds) => {
        setDatasource(ds);
      });
  }, [props.uid]);

  const effectiveQuery = useMemo(
    () => (datasource?.type === 'loki' ? render(props.config) : ''),
    [props.config, datasource]
  );

  const parser = useMemo(() => {
    let string = ' | ';
    if (!props.config || !props.config.parserConfig) {
      return '';
    }

    let _parser = JSON.parse(props.config.parserConfig);
    if (_parser === null || _parser.parser.value === null) {
      return '';
    }

    string += _parser.parser.value;

    let quote = _parser.parser.quote === true ? '`' : '';

    if (_parser.params && _parser.params !== '') {
      string += ' ' + quote + _parser.params + quote;
    }

    if (_parser.additionalExpr) {
      string += ' | ' + _parser.additionalExpr;
    }

    return string;
  }, [props.config]);

  const explore = useCallback(() => {
    const exploreURL = getExploreUrl({
      datasource: {
        uid: props.uid,
        name: props.name,
      } as DataSourceApi,
      request: {
        app: 'grafana-detect-app',
        interval: '5s',
        range: {
          raw: {
            from: 'now-1h',
            to: 'now',
          },
        },
        targets: [
          {
            refId: 'A',
            datasource: props.uid,
            expr: `${effectiveQuery}`,
          } as LokiQuery,
        ] as Array<DataQuery>,
      } as DataQueryRequest,
    });

    window.open(exploreURL, '_blank');
  }, [effectiveQuery, props.uid, props.name]);

  return (
    <>
      <TableRow>
        <div style={{ flexDirection: 'row' }}>
          <Checkbox
            checked={props.logsource.includes(props.id)}
            className={styles.headerCheckbox}
            style={{ marginTop: -2 }}
            onClick={() => {
              props.onSelect();
            }}
          />
          {props.description && (
            <IconButton
              className={styles.headerCheckbox}
              name={open ? 'angle-down' : 'angle-right'}
              tooltip="Expand"
              size="xs"
              onClick={() => setOpen(!open)}
            />
          )}
        </div>
        <div>{props.name}</div>
        <div>
          <LogSourceDataSource uid={props.uid} />
        </div>
        <div style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
          {effectiveQuery && <code>{effectiveQuery}</code>}
        </div>
        <RowButtons explore={!!effectiveQuery} onExplore={explore} id={props.id} onDelete={props.onDelete} />
      </TableRow>
      {open && (
        <TableRow>
          <div className={styles.expand} />
          <div className={styles.infoPane}>
            <p>{props.description}</p>
          </div>
        </TableRow>
      )}
    </>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  headerCheckbox: css({
    padding: theme.spacing.x0_25,
    margin: theme.spacing.x0_25,
  }),
  infoPane: css({
    gridColumnStart: 2,
    gridColumnEnd: 6,
  }),
  expand: css({
    transform: 'translateX(-1rem) translateY(-0.5rem)',
    borderColor: theme.colors.border.weak,
    borderRight: 'solid 1px',
  }),
});
