import { getAppEvents } from '@grafana/runtime';
import { PLUGIN_ID } from 'shared/constants';
import { useCallback, useMemo } from 'react';
import { firstValueFrom } from 'rxjs';
import { FetchJobApi, Configuration as GithubRepoApiCfg } from 'api/detect-service';
import { AppEvents } from '@grafana/data';
import { useTranslation } from 'react-i18next';

export const useForceFetchRepo = () => {
  const { t } = useTranslation();
  const fetchJobApi = useMemo(
    () =>
      new FetchJobApi(
        new GithubRepoApiCfg({
          basePath: `/api/plugins/${PLUGIN_ID}/resources/service/v1/db`,
        })
      ),
    []
  );

  const forceFetchRepo = useCallback(
    (repoId: string) => {
      firstValueFrom(fetchJobApi.createRunNowFetchJob({ createRunNowFetchJobRequestModel: { repo_id: repoId } })) //eslint-disable-line camelcase
        .catch((e) => {
          getAppEvents().publish({
            type: AppEvents.alertError.name,
            payload: [t('errors.repository.forceFetchError'), e],
          });
          console.error('Cannot force fetch repository', repoId);
        })
        .then(() => {
          getAppEvents().publish({
            type: AppEvents.alertSuccess.name,
            payload: [t('errors.repository.forceFetchSuccess'), repoId],
          });
        });
    },
    [fetchJobApi, t]
  );

  return [forceFetchRepo] as const;
};
