/* eslint-disable camelcase */

import React from 'react';
import { sentenceCase } from 'utils/helpers';
import { ControlledCollapse, Icon, Modal, useStyles2 } from '@grafana/ui';
import { GrafanaTheme2 } from '@grafana/data';
import { css } from '@emotion/css';
import { ErrorDetail, ErrorResponsePayload } from 'shared/types';

export const ErrorModal = (payload: ErrorResponsePayload): React.JSX.Element => {
  const _msg = payload.props.message.split(':');
  const title = _msg[_msg.length - 1].trim();
  const status = sentenceCase(payload.props.status);
  const styles = useStyles2(getStyles);

  return (
    <Modal
      title={
        <div className={styles.modalHeaderTitle}>
          <Icon name="exclamation-triangle" size="xxl" />
          <span className={styles.titleText}>{status}</span>
        </div>
      }
      isOpen={payload.isOpen}
      onDismiss={payload.onDismiss}
    >
      <>
        <h4>{title}</h4>
        <div className={styles.message}>
          {payload.props.error_details &&
            payload.props.error_details.length > 0 &&
            payload.props.error_details.map((detail: ErrorDetail) => {
              return (
                Object.entries(detail)
                  .map(([key, value]) => {
                    if (key === 'metadata') {
                      return <b key={key}>{value.message}</b>;
                    }
                    return null;
                  })
                  // schenanigans to remove nulls and silences the warning
                  // about not returning a value in a map function.
                  .filter(Boolean)
              );
            })}
        </div>
        {payload.props.error_details && payload.props.error_details.length > 0 && (
          <ControlledCollapse label="Error Details" isOpen={false} collapsible={true}>
            {getErrorDetails(payload.props.error_details, styles)}
          </ControlledCollapse>
        )}
      </>
    </Modal>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  titleText: css({
    paddingLeft: theme.spacing(2),
  }),
  modalHeaderTitle: css({
    fontSize: theme.typography.h1.fontSize,
    float: 'left',
    paddingTop: theme.spacing(1),
    margin: theme.spacing(0, 2),
  }),
  message: css({
    marginBottom: theme.spacing(2),
  }),
  row: css({
    margin: theme.spacing(0, 0),
  }),
  key: css({
    fontWeight: 'bold',
    color: theme.colors.error.shade,
  }),
});

function getErrorDetails(error_details: Array<ErrorDetail>, styles: any): Array<Array<React.JSX.Element>> {
  return error_details.map((detail: ErrorDetail) => {
    return Object.entries(detail).map(([key, value]) => {
      return (
        <p key={key} className={styles.row}>
          <span className={styles.key}>{key}</span>: {typeof value === 'object' ? JSON.stringify(value) : value}
        </p>
      );
    });
  });
}
