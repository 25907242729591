import React from 'react';
import { IconButton } from '@grafana/ui';
import { PLUGIN_ROOT } from '../../../shared/constants';

interface RowButtonsProps {
  id: string;
  explore?: boolean;
  onExplore?: () => void;
  onDelete?: () => void;
}

export const RowButtons = (props: RowButtonsProps) => {
  return (
    <div>
      <IconButton
        data-testid="edit-button"
        name="edit"
        tooltip="edit"
        onClick={() => {
          window.open(`${PLUGIN_ROOT}/scenes/logsource/editor?var-logsource=${props.id}`);
        }}
      />
      <IconButton
        data-testid="delete-button"
        name="trash-alt"
        tooltip="delete"
        onClick={props.onDelete}
        disabled={!props.onDelete}
      />
      <IconButton
        data-testid="explore-button"
        name="search"
        tooltip="explore"
        onClick={props.onExplore}
        disabled={!props.explore}
      />
    </div>
  );
};
