import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Button, FieldSet, InlineField, Input, Legend, useStyles2 } from '@grafana/ui';
import { PluginConfigPageProps, AppPluginMeta, PluginMeta, GrafanaTheme2 } from '@grafana/data';
import { FetchResponse, getBackendSrv } from '@grafana/runtime';
import { css } from '@emotion/css';
import { lastValueFrom, firstValueFrom, EMPTY, delay, expand, reduce } from 'rxjs';
import { DetectService } from 'shared/serviceTypes';
import { AccessPolicyToken, PRODUCT_NAME } from 'shared/constants';
import { SigmaAPIConfig } from 'shared/sigmaAPITypes';
import { EnableButton } from 'components/configuration/EnableButton';
import { SigmaRepoForm } from 'components/configuration/repository-info';
import { CloudAccessPolicy } from 'components/configuration/CloudAccessPolicy';
import { CustomRepository } from 'components/configuration/custom-repo';
import { AlertingForm } from 'components/configuration/AlertingForm';
import { AlertingConfig } from 'shared/alertingTypes';
import { ErrorResponse, FolderListResponse } from 'shared/requests/alerts';
import { GithubRepoApi } from 'api/detect-service';
import { DetectServiceConfig } from '../shared/apiConfigs';
import { DashboardConfig } from 'shared/dashboardTypes';

export type DetectSettings = {
  alerting?: AlertingConfig;
  service?: DetectService;
  sigmaAPIConfig?: SigmaAPIConfig;
  dashboardConfig?: DashboardConfig;
  configured?: boolean;
};

export interface AppConfigProps extends PluginConfigPageProps<AppPluginMeta<DetectSettings>> {}

export const AppConfig = ({ plugin }: AppConfigProps) => {
  const s = useStyles2(getStyles);
  const { enabled, jsonData, id, secureJsonFields } = plugin.meta;
  const [customRepoList, setCustomRepoList] = useState(Array<React.JSX.Element>);

  const [service, _] = useState<DetectService>({
    ...jsonData?.service,
  });

  const [sigmaAPIConfig, setSigmaConfig] = useState<SigmaAPIConfig>({
    url: 'localhost:3020',
    ...jsonData?.sigmaAPIConfig,
  });

  const githubRepoApi = useMemo(() => new GithubRepoApi(DetectServiceConfig), []);

  useEffect(() => {
    refreshCustomRepos(setCustomRepoList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [alerting, setAlertingConfig] = useState<AlertingConfig>(jsonData?.alerting ?? { namespace: PRODUCT_NAME });

  const [dashboardConfig, setDashboardConfig] = useState<DashboardConfig>(jsonData?.dashboardConfig ?? {});

  const componentListFolders = useCallback(listFolders, []);

  const listCustomRepos = async () => {
    return await firstValueFrom(githubRepoApi.listGithubRepo({ page: 1, itemsPerPage: 1000 }));
  };

  // f is the function to set the state of the customRepoList
  // it's set to setCustomRepoList
  // This allows to have access to this function here so that we can update the list
  // f(newList)
  const refreshCustomRepos = (f: any) => {
    // Empty the list of custom repos before fetching the new list
    f([]);
    listCustomRepos()
      .then((data) => {
        let newList: Array<React.JSX.Element> = [];
        let repoList = data.data;
        repoList.forEach((element) => {
          if (element.name !== 'Main repository') {
            newList.push(
              <CustomRepository
                isNew={false}
                uuid={element.id}
                key={'custom' + newList.length}
                onSaved={() => {
                  refreshCustomRepos(f);
                }}
                onDeleted={() => {
                  refreshCustomRepos(f);
                }}
              />
            );
          }
        });
        f(newList);
      })
      .catch((error: any) => {
        console.error("Can't list custom repos", error);
      });
  };

  return (
    <>
      <div className="gf-form-group">
        <div>
          {/* Enable the plugin */}
          <Legend>Enable / Disable</Legend>
          <EnableButton
            enabled={enabled}
            onClick={(enable) => {
              configure(id, enable, sigmaAPIConfig, service, alerting, dashboardConfig);
            }}
          />
        </div>
      </div>
      {enabled ? (
        <>
          <CloudAccessPolicy
            configured={(secureJsonFields && secureJsonFields[AccessPolicyToken]) ?? false}
            onToken={(token) => {
              configure(id, enabled, sigmaAPIConfig, service, alerting, dashboardConfig, token);
            }}
          />
          <div className="gf-form-group">
            {/*
              We should move this usage of state in the future, this could result in
              quite deep prop drilling. The components need to move out to avoid
              this page becoming overly large - JC 2023-02-03
              */}
            <SigmaRepoForm />
            <FieldSet label="Custom repositories">
              {customRepoList}
              <Button
                variant="secondary"
                icon="plus"
                onClick={() =>
                  setCustomRepoList(
                    customRepoList.concat(
                      <CustomRepository
                        isNew={true}
                        uuid={''}
                        key={'custom' + customRepoList.length}
                        onSaved={() => {
                          refreshCustomRepos(setCustomRepoList);
                        }}
                        onDeleted={() => {
                          refreshCustomRepos(setCustomRepoList);
                        }}
                      />
                    )
                  )
                }
              >
                Add Custom Repository
              </Button>
            </FieldSet>

            <AlertingForm
              alertingConfig={alerting}
              setAlertingConfig={setAlertingConfig}
              listFolders={componentListFolders}
            />

            <FieldSet label="Dashboards">
              <InlineField label="Alerting Dashboard UID" tooltip={<>The UID of the alerting dashboard.</>}>
                <Input
                  type="text"
                  value={dashboardConfig.alertingDBUid}
                  onChange={(evt) => {
                    setDashboardConfig({
                      ...dashboardConfig,
                      alertingDBUid: evt.currentTarget.value,
                    });
                  }}
                />
              </InlineField>
            </FieldSet>

            <div>
              <Button
                className={s.marginTop}
                variant="primary"
                onClick={() => {
                  configure(id, enabled, sigmaAPIConfig, service, alerting, dashboardConfig);
                }}
              >
                Save Configuration
              </Button>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  colorWeak: css`
    color: ${theme.colors.text.secondary};
  `,
  marginTop: css`
    margin-top: ${theme.spacing(3)};
  `,
});

const configure = async (
  pluginId: string,
  enabled: boolean,
  sigmaAPIConfig: SigmaAPIConfig,
  service: DetectService,
  alerting: AlertingConfig,
  dashboard?: DashboardConfig,
  capToken?: string
) => {
  await updatePluginAndReload(pluginId, {
    enabled,
    pinned: enabled,
    jsonData: {
      service,
      sigmaAPIConfig: sigmaAPIConfig,
      alerting: alerting,
      dashboardConfig: dashboard,
    },
    secureJsonData: {
      [AccessPolicyToken]: capToken ?? undefined,
    },
  });
};

const updatePluginAndReload = async (pluginId: string, data: Partial<PluginMeta>) => {
  try {
    await updatePlugin(pluginId, data);

    // Reloading the page as the changes made here wouldn't be propagated to the actual plugin otherwise.
    // This is not ideal, however unfortunately currently there is no supported way for updating the plugin state.
    window.location.reload();
  } catch (e) {
    console.error('Error while updating the plugin', e);
  }
};

export const updatePlugin = async (pluginId: string, data: Partial<PluginMeta>) => {
  const response = getBackendSrv().fetch({
    url: `/api/plugins/${pluginId}/settings`,
    method: 'POST',
    data,
  });
  return lastValueFrom(response);
};

export const listFolders = (search: string, limit = 1000) => {
  let page = 1;
  const request = (l: number, p: number) =>
    getBackendSrv().fetch<Array<FolderListResponse> | ErrorResponse>({
      url: `/api/folders`,
      params: {
        limit: l,
        page: p,
      },
      method: 'GET',
      responseType: 'json',
    });
  return lastValueFrom(
    request(limit, page).pipe(
      // We don't need to worry about the deprecation warning in this case,
      // it is only valid for situations where a SchedulerLike argument is
      // passed to the expand function which is not the case here.
      //
      //eslint-disable-next-line deprecation/deprecation
      expand((r) => {
        if ('length' in r.data) {
          if (r.data.length >= limit) {
            delay(500); // avoid overloading Grafana instance with too many requests
            return request(limit, ++page);
          }
          return EMPTY;
        } else {
          throw new Error(`Error whilst fetching folders from Grafana: ${r.data.message ?? ''}`);
        }
      }, 1), // no more than 1 concurrent request
      reduce<FetchResponse<Array<FolderListResponse> | ErrorResponse>, Array<FolderListResponse>>((acc, res) => {
        if ('length' in res.data) {
          return acc.concat(res.data.filter((v) => v.title.match(new RegExp(search, 'i'))));
        } else {
          throw new Error(`Error whilst fetching folders from Grafana: ${res.data.message ?? ''}`);
        }
      }, [])
    )
  );
};
