import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Field, TagList, TimeRangePicker } from '@grafana/ui';
import { VerticalContainer } from 'components/containers/Vertical';
import { FormatTabs } from './FormatTabs';
import { SelectableValue, TimeRange } from '@grafana/data';
import { StatefulDatasourceQueryField } from './DatasourceQueryField';
import { ConversionContext } from 'stores/conversion';
import { getDefaultTimeRange } from 'utils/datetime';
import { Set } from 'immutable';
import { LogsourceDropdown } from '../scenes/logsource/logsource-dropdown';
import { DataSourceConfigReadModel } from '../../api/detect-service';
import { QueryEditorTags } from '../scenes/logsource/queryEditorTags';
import { QueryEditorFields } from '../scenes/logsource/queryEditorFields';
import { field } from 'fp-ts';
import { PipelineContext } from '../../stores';

interface ConversionResultProps {
  formats?: Array<SelectableValue<string>>;
  format?: SelectableValue<string>;
  tags?: Array<string>;
  fields?: Array<string>;
  datasourceConfig: DataSourceConfigReadModel | undefined;
  timeRangeZone?: [TimeRange, string];

  setFormat: (format: SelectableValue<string>) => void;
  setTimeRangeZone: (timeRangeZone: [TimeRange, string]) => void;
  onFetchLabels?: () => void;
  onSetLogsource?: (ds: DataSourceConfigReadModel) => void;
}

interface StatefulConversionResultProps {}

export const ConversionResult = ({
  formats,
  format,
  tags = [],
  fields = [],
  setFormat,
  onFetchLabels,
  onSetLogsource,
  datasourceConfig,
  timeRangeZone,
  setTimeRangeZone,
}: ConversionResultProps) => {
  const fieldSet = useMemo(() => Set(fields), [fields]);

  const tagSet = useMemo(() => {
    return [...tags].sort((a, b) => {
      let gotA = fieldSet.has(a),
        gotB = fieldSet.has(b);
      return gotA < gotB ? 1 : gotA === gotB ? 0 : -1;
    });
  }, [fieldSet, tags]);

  return (
    <VerticalContainer defaultOpen breakPoint={1200} label="Output">
      <Field>
        <>
          <LogsourceDropdown
            value={datasourceConfig}
            onChange={(val) => {
              onSetLogsource && onSetLogsource(val);
              onFetchLabels && onFetchLabels();
            }}
          />
          <QueryEditorTags tags={tagSet} fields={fields} />
          <QueryEditorFields fields={fields} tags={tagSet} />
        </>
      </Field>
      <Field label="Time Range" description="Choose the time range you want to test the query against">
        <TimeRangePicker
          value={(timeRangeZone && timeRangeZone[0]) ?? getDefaultTimeRange()}
          onChange={(timeRange: TimeRange) => setTimeRangeZone([timeRange, 'UTC'])}
          onChangeTimeZone={function (timeZone: string): void {
            setTimeRangeZone([timeRangeZone![0], timeZone]);
          }}
          onChangeFiscalYearStartMonth={function (_: number): void {}}
          onMoveBackward={function (): void {}}
          onMoveForward={function (): void {}}
          onZoom={function (): void {}}
          timeZone="UTC"
        />
      </Field>
      <FormatTabs
        activeFormat={format?.value ?? 'default'}
        formats={formats}
        onChange={(tab) => {
          let f = formats?.filter((e) => e.value === tab);
          if (f && f.length > 0) {
            setFormat(f[0]);
          }
        }}
      />
      <StatefulDatasourceQueryField />
    </VerticalContainer>
  );
};

export const StatefulConversionResult = (props: StatefulConversionResultProps) => {
  const [formats, setFormats] = useState<Array<SelectableValue<string>>>([]);
  const [format, setFormat] = useState<SelectableValue<string>>({});
  const [timeRangeZone, setTimeRangeZone] = useState<[TimeRange, string]>();
  const [tags, setTags] = useState<Array<string>>([]);
  const [fields, setFields] = useState<Array<string>>([]);
  const [datasourceConfig, setDatasourceConfig] = useState<DataSourceConfigReadModel>();

  const {
    data: {
      formats: stateFormats,
      format: stateFormat,
      timeRangeZone: stateTimeRangeZone,
      lokiParserAndLabelKeys: stateLokiParserAndLabelKeys,
      fields: stateFields,
      datasourceConfig: stateDatasourceConfig,
    },
    operations: {
      setFormat: doSetFormat,
      setTimeRangeZone: doSetTimeRange,
      getLokiParserAndLabelKeys: doGetLokiParserAndLabelKeys,
      setDatasourceConfig: doSetDatasourceConfig,
    },
  } = useContext(ConversionContext);

  const {
    operations: { setLogSourcePipelines: doSetLogSourcePipelines },
  } = useContext(PipelineContext);

  useEffect(() => {
    stateFormats.subscribe(setFormats);
    stateFormat.subscribe(setFormat);
    stateTimeRangeZone.subscribe(setTimeRangeZone);
    stateLokiParserAndLabelKeys.subscribe((data) => {
      if (data) {
        setTags(data.extractedLabelKeys);
      }
    });
    stateFields.subscribe((data) => {
      setFields(Set(data.flat()).sort().toArray());
    });
    stateDatasourceConfig.subscribe(setDatasourceConfig);
  }, [stateFormats, stateFormat, stateTimeRangeZone, stateLokiParserAndLabelKeys, stateFields, stateDatasourceConfig]);

  return (
    <ConversionResult
      {...props}
      setFormat={doSetFormat}
      formats={formats}
      format={format}
      tags={tags}
      fields={fields}
      setTimeRangeZone={(e: [TimeRange, string]) => {
        doSetTimeRange(e);
      }}
      timeRangeZone={timeRangeZone}
      onFetchLabels={doGetLokiParserAndLabelKeys}
      onSetLogsource={(e) => {
        doSetDatasourceConfig(e, (pipelines) => doSetLogSourcePipelines(pipelines.data.map((e) => e.id)));
      }}
      datasourceConfig={datasourceConfig}
    />
  );
};
