import React, { useState } from 'react';
import { Select } from '@grafana/ui';
import { useTranslation } from 'react-i18next';
import { parseDuration, SelectableValue } from '@grafana/data';
import { formatDuration } from 'date-fns';

// Default intervals used in the refresh picker component
export const defaultIntervals = ['0s', '5s', '10s', '30s', '1m', '5m', '15m', '30m', '1h', '2h', '1d'];

export interface IntervalPickerProps {
  intervals?: Array<string>;
  onIntervalChanged: (interval: string) => void;
  value?: string;
  tooltip?: string;
  showAutoInterval?: boolean;
}

export const IntervalPicker = (props: IntervalPickerProps) => {
  const { intervals, value, tooltip, showAutoInterval } = props;
  const currentValue = value || '';
  const options = intervalsToOptions({ intervals, showAutoInterval });
  const option = options.find(({ value }) => value === currentValue);
  const [selectedValue, setSelectedValue] = useState<SelectableValue<string> | null>(option || null);
  const [invalid, setInvalid] = useState<boolean>(false);
  const { t } = useTranslation();

  const onChangeSelect = (item: SelectableValue<string>) => {
    const { onIntervalChanged } = props;
    const duration = parseDuration(item?.value ?? '');
    if (item?.value && Object.keys(duration).length === 0) {
      setSelectedValue(item);
      setInvalid(true);
    } else {
      setInvalid(false);
      if (onIntervalChanged && duration) {
        onIntervalChanged(item?.value || '');
        setSelectedValue(item);
      } else if (!item.value) {
        setSelectedValue(null);
      }
    }
  };

  return (
    <Select
      value={selectedValue}
      options={options}
      onChange={onChangeSelect}
      aria-label={selectedValue?.ariaLabel}
      tooltip={tooltip}
      isClearable={true}
      allowCustomValue={true}
      invalid={invalid}
    />
  );
};

export function intervalsToOptions({
  intervals = defaultIntervals,
}: { intervals?: Array<string>; showAutoInterval?: boolean } = {}): Array<SelectableValue<string>> {
  const options: Array<SelectableValue<string>> = intervals.map((interval) => {
    const duration = parseDuration(interval);
    const ariaLabel = formatDuration(duration);

    return {
      label: interval,
      value: interval,
      ariaLabel: ariaLabel,
    };
  });
  return options;
}
